import React from 'react'
import getCookie from '../utils/getCookie'
import config from '../config/config'

export default class SendReply extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      activated:false
    }
    this.handleText = this.handleText.bind(this)
    this.sendReply = this.sendReply.bind(this)
    this.handleActivatedReply = this.handleActivatedReply.bind(this)
  }

  componentDidMount(){
  }

  handleText(e){
    this.setState({
      text:e.target.value
    })
  }
  handleTitle(e){
    this.setState({
      title:e.target.value
    })
  }

  sendReply(e){
    e.stopPropagation()
    e.preventDefault()
    if (getCookie('token') && !this.state.sendingComment){
      this.setState({sendingComment:true})
      fetch(config.backtAdress + '/userRequest',{
      method:'post',
      headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+getCookie('token')
      },
      body:JSON.stringify({
        query:'sendComment',
        parent:this.props.postId,
        text:this.state.text
        })
      })
      .then(res=>res.json())
      .then(res=>{
        console.log(res)
        if (res.success){
          this.setState({
            sendingComment:false
          })
          this.handleActivatedReply()
          this.props.addNewPosts(this.props.postId,[res.comment])
        }
      })
    }
  }

  handleActivatedReply(){
    if (!this.state.pretendToSendComment){
      if (!getCookie('token')){
        this.props.showLoginComponent()
      }
      this.setState({
        activated:!this.state.activated
      })
    }
  }

  handlePretendToSendComment(mouse){
    if (mouse === 'in'){
      this.setState({pretendToSendComment:true})
    } else {
      if (!this.state.showLoginComponent){
        this.setState({pretendToSendComment:false})
      }
    }
  }

  render(){ 
    if (this.state.activated){
      return (
        <div className='container-fluid p-0 pr-2'>
          <h5 className='font-sm pt-3 text-secondary'>Reply</h5>
          <form 
            onSubmit={this.sendReply}>
            <textarea 
            required
            onBlur={this.handleActivatedReply}  
            ref={input => input && input.focus()}
            onChange={this.handleText} 
            placeholder='Content'
            className='form-control form-control-sm shadow-sm border border-primary'>
            </textarea>
            <div className='pt-2'>
              <button 
              onMouseEnter = {()=>this.handlePretendToSendComment('in')}
              onMouseLeave = {()=>this.handlePretendToSendComment('out')}
              type='submit'
              className='btn btn-primary btn-sm shadow'>
                Send
              </button>
            </div>
          </form>
        </div>
      )
    } else {
      return (
        <div className='p-0 ml-2 col '>
          <button className='p-0 btn btn-link no-decoration pr-2'>
            <h5 onClick={this.handleActivatedReply} className='font-smaller text-secondary'>Reply</h5>
          </button>
        </div>
      )
    }
  }
}