import React from 'react'
import {BsFillHouseFill, BsFillPersonFill, BsFillCalendarFill} from 'react-icons/bs'
import {GiCampfire} from 'react-icons/gi'
import {FiSearch} from 'react-icons/fi'
import {FaWpexplorer} from 'react-icons/fa'
import {IoMdNotifications} from 'react-icons/io'

import getCookie from '../../utils/getCookie';

import {Link} from 'react-router-dom'

const sideMenyStyle = props => {
  return {
    zIndex:2,
    maxWidth:props.sideMenuMaxWidth,
  }
}

const sideItemStyle = props =>{
  return {
    fontSize:32,
    transform:'translate3D(0,-4px,0)'
  }
}

export default class SideMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const sideMenuMaxWidth = this.state.width > 800 ? 200 : 40
    return (
      <div style={sideMenyStyle({sideMenuMaxWidth:sideMenuMaxWidth})} className='col p-1'>
        <div style={{position:'fixed',maxWidth:sideMenuMaxWidth}}>
          <div>
            <Link 
              to={'/'}
              className='row no-decoration text-dark d-flex align-items-end m-0 p-0'>
              <BsFillHouseFill style={sideItemStyle()}/>
              {sideMenuMaxWidth === 200 ? <h5 className='d-inline pl-1'>Home</h5> : null}
            </Link>
          </div>
          
          <div className='pt-2'>
            <Link to={'/search'} className='row no-decoration text-dark d-flex align-items-end m-0 p-0'>
              <FiSearch style={sideItemStyle()}/>
              {sideMenuMaxWidth === 200 ? <h5 className='d-inline pl-1'>Search</h5> : null}
            </Link>
          </div>

          <div className='pt-2'>
            <Link to={'/trending'} className='row no-decoration text-dark d-flex align-items-end m-0 p-0'>
              <GiCampfire style={sideItemStyle()}/>
              {sideMenuMaxWidth === 200 ? <h5 className='d-inline pl-1'>Trending</h5> : null}
            </Link>
          </div>

          <div className='pt-2'>
            <Link to={'/'} className='row no-decoration text-dark d-flex align-items-end m-0 p-0'>
              <FaWpexplorer style={sideItemStyle()}/>
              {sideMenuMaxWidth === 200 ? <h5 className='d-inline pl-1'>Explore</h5> : null}
            </Link>
          </div>

          <div className='pt-2'>
            <Link to={'/'} className='row no-decoration text-dark d-flex align-items-end m-0 p-0'>
              <IoMdNotifications style={sideItemStyle()}/>
              {sideMenuMaxWidth === 200 ? <h5 className='d-inline pl-1'>Notifications</h5> : null}
            </Link>
          </div>

          <div className='pt-2'>
            <Link to={`/profile/${getCookie('userId')}`} className='row no-decoration text-dark d-flex align-items-end m-0 p-0'>
              <BsFillPersonFill style={sideItemStyle()}/>
              {sideMenuMaxWidth === 200 ? <h5 className='d-inline pl-1'>Profile</h5> : null}
            </Link>
          </div>

          <div className='pt-2'>
            <Link to={'/calendar'} className='row no-decoration text-dark d-flex align-items-end m-0 p-0'>
              <BsFillCalendarFill style={sideItemStyle()}/>
              {sideMenuMaxWidth === 200 ? <h5 className='d-inline pl-1'>Calendar</h5> : null}
            </Link>
          </div>

          

        </div>
      </div>
    )
  }
}