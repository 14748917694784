import React from 'react'
import config from '../config/config.json'
import setCookie from '../utils/setCookie'
import { store } from 'react-notifications-component'
import ReactNotification from 'react-notifications-component'

const loginBackground = {
  minHeight:100,
  alignItems:'center',  
  backgroundSize:'cover',
  backgroundRepeat:'no-repeat',
  backgroundPosition:'60% 60%',
}

export default class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.sendRegisterForm = this.sendRegisterForm.bind(this)
  }

  createNotification = (title, message, type) => {
    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  };

  sendRegisterForm(e){
    e.preventDefault();
    if (this.state.password !== this.state.passwordConfirm){
      this.createNotification("Falha de cadastro","Confirmação de senha errada","danger")
      return null
    }
    fetch(config.backtAdress+'/newUser',{
        method:'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body:JSON.stringify({
            email:this.state.email,
            password:this.state.password,
            name:this.state.name,
            lastname:this.state.lastname
        })
    })
    .then(res=>res.json())
    .then(res=>{
        if (res.error){
            if (res.error === 'E-mail already used'){
              this.createNotification("Falha de cadastro","Este e-mail está sendo utilizado por outro usuário.","danger")
            }
            return null
        }
        setCookie('token',res.token);
        setCookie('name',res.newUser.name);
        setCookie('lastname',res.newUser.lastname);
        setCookie('email',res.newUser.email);
        this.props.hideLoginComponent()
    })
  }

  handdleInputs(key,value){
    let object = {}
    object[key] = value
    this.setState(object)
  }

  render() {
    return (
      <div className='container p-0'>
        <ReactNotification />
        <div className='container p-2 text-center '>
          <div className='bg-white rounded-lg shadow ease-in-out container-fluid p-0 m-0 border-primary row'>
            <div style={loginBackground} className='col-sm shadow-sm panel'>
              <div className='text-md p-3 mt-5 text-white '>Caravana</div>
            </div>
            <div style={{minHeight:480}} className='col-sm p-2'>
            <div className='pt-3 text-secondary '>Sign up</div>
              <div>
                <form onSubmit={this.sendRegisterForm} className='form-group'>
                  <div className='text-left'>Name</div>
                  <input onChange={(e)=>this.handdleInputs('name',e.target.value)} type='text' className='form-control form-control-sm' required></input>
                  <div className='text-left mt-1'>Lastname</div>
                  <input onChange={(e)=>this.handdleInputs('lastname',e.target.value)} type='test' className='form-control form-control-sm' required></input>
                  <div className='text-left mt-1'>E-mail</div>
                  <input onChange={(e)=>this.handdleInputs('email',e.target.value)}  type='email' className='form-control form-control-sm' required></input>
                  <div className='text-left mt-1'>Password</div>
                  <input onChange={(e)=>this.handdleInputs('password',e.target.value)}  type='password' className='form-control form-control-sm' required></input>
                  <div className='text-left mt-1'>Password Confirm</div>
                  <input onChange={(e)=>this.handdleInputs('passwordConfirm',e.target.value)}  type='password' className='form-control form-control-sm' required></input>
                  <button className='btn shadow p-2 mt-1 btn-block btn-secondary'>Create Account</button>
                </form>
                <div className='mt-2'>
                  Already a member?
                </div>
                <button className='btn btn-outline-secondary mt-2 p-0 pr-1 pl-1 rounded' onClick={this.props.toggleSignUp}>Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}