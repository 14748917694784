import React from 'react'
import getCookie from '../utils/getCookie'
import config from '../config/config'
import Loading from '../components/loadingAnimation'

const SendButton = () => {
  return <button style={{minWidth:70}} type='submit' className='btn btn-primary shadow'>Send</button>
}

export default class SendComment extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
    this.textRef = new React.createRef()
    this.handleText = this.handleText.bind(this)
    this.sendComment = this.sendComment.bind(this)
  }

  componentDidMount(){
  }

  handleText(event){
    this.setState({
      text:event.target.value
    })
  }

  handleTitle(event){
    this.setState({
      title:event.target.value
    })
  }

  sendComment(e){
    e.preventDefault()
    if (getCookie('token')){
      this.setState({sendingComment:true})
      const parentId = this.props.postId
      const text = this.state.text
      fetch(config.backtAdress + '/userRequest',{
      method:'post',
      headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+getCookie('token')
      },
      body:JSON.stringify({
        query:'sendComment',
        parent:parentId,
        text:text,
        groupCode:this.props.groupCode
        })
      })
      .then(res=>res.json())
      .then(res=>{
        if (res.success){
          this.setState({
            sendingComment:false,
            text:''
          })
          this.textRef.current.value = ''
          this.props.addNewPosts(this.props.postId,[res.comment])
        }
      })
    } else {
      this.props.showLoginComponent()
    }
  }

  render(){ 
    return (
      <div className='container-fluid p-0'>
        <h5 className='font-sm pt-3'>Leave a comment</h5>
        <form onSubmit={this.sendComment}>
          <textarea ref={this.textRef} onChange={this.handleText} placeholder='Comment' className='form-control form-control-sm border-0 shadow-sm' required></textarea>
          <div className='pt-2 text'>
            {this.state.sendingComment ? <button style={{ minWidth: 70 }} type='submit' className='btn btn-primary shadow'><Loading type={'small'}/></button> : <SendButton/>}
          </div>
        </form>
      </div>
    );
  }
}