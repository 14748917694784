import React from 'react'

import Navbar from '../components/navbar'
import SideMenu from '../components/sideMenu/sideMenu'

//import SendPostAtHome from '../components/sendPostAtHome'

export default class SearchPage extends React.Component{
  constructor (props){
    super(props)
    this.state = {}
    this.hideLoginComponent = this.hideLoginComponent.bind(this)
    this.showLoginComponent = this.showLoginComponent.bind(this)
    this.toggleSignUp = this.toggleSignUp.bind(this)
    this.turnOffRequestMyVotes = this.turnOffRequestMyVotes.bind(this)
  }

  showLoginComponent(props){
    if (props && props.signUp){
      this.setState({signUpState:true})
    } else {
      this.setState({signUpState:false})
    }
    this.setState({loginState:true})
  }
  hideLoginComponent(){
    this.setState({
      loginState:false,
      requestMyVotes:true
    })
  }

  toggleSignUp(){
    this.setState({
      signUpState:!this.state.signUpState
    })
  }

  turnOffRequestMyVotes(){
    this.setState({
      requestMyVotes:false
    })
  }

  render (){
    return (
      <div className='bg-light'>
        <div style={{zIndex:999,position:'fixed'}}>
          <Navbar 
          loginState={this.state.loginState}
          signUpState={this.state.signUpState}
          showLoginComponent={this.showLoginComponent}
          hideLoginComponent={this.hideLoginComponent}
          toggleSignUp = {this.toggleSignUp}
          />
        </div>
        <div className='p-nav'></div>
        <div style={{maxWidth:900}} className='container-fluid bg-light p-0'>
          <div  className='row container-fluid p-0 m-0'>
            <SideMenu/>
            <div className='col p-0'>
              Hello world from search
            </div>
          </div>
        </div>
      </div>
    );
  }
}