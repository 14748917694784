import config from '../../config/config.json'
import getCookie from '../getCookie'

const follow = props => {
  const {reference, showLoginComponent, active } = props
  console.log(active)
  if (getCookie('token')) {
    fetch(config.backtAdress + '/userRequest', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getCookie('token')
      },
      body: JSON.stringify({
        query: 'follow',
        reference,
        active
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.err){
        console.log(res.err)
      }
      return res
    })
  } else {
    showLoginComponent()
  }
}
export default follow