import React from 'react'
import getCookie from '../utils/getCookie'
import config from '../config/config'
import Login from '../components/login'
import SignUp from '../components/signUp'
import Loading from '../components/loadingAnimation'


const SendButton = () => {
  return <button style={{minWidth:70}} type='submit' className='btn btn-secondary shadow'>Send</button>
}

export default class sendPost extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
    this.handleText = this.handleText.bind(this)
    this.handleTitle = this.handleTitle.bind(this)
    this.sendPost = this.sendPost.bind(this)
    this.hideLoginComponent = this.hideLoginComponent.bind(this)
    this.titleRef = new React.createRef()
    this.textRef = new React.createRef()
    this.toggleSignUp = this.toggleSignUp.bind(this)
  }

  componentDidMount(){
  }

  handleText(e){
    this.setState({
      text:e.target.value
    })
  }
  handleTitle(e){
    this.setState({
      title:e.target.value
    })
  }

  sendPost(e){
    e.preventDefault()
    if (getCookie('token')){
      this.setState({sendingPost:true})
      fetch(config.backtAdress + '/userRequest',{
      method:'post',
      headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+getCookie('token')
      },
      body:JSON.stringify({
        query:'sendPost',
        groupCode:this.props.groupCode,
        groupName:this.props.groupName,
        title:this.state.title,
        text:this.state.text
        })
      })
      .then(res=>res.json())
      .then(res=>{
        if (res.success){
          this.setState({
            sendingPost:false,
            title:'',
            text:''
          })
          this.textRef.current.value = ''
          this.titleRef.current.value = ''
          this.props.addNewPosts([res.newPost])
        }
      })
    } else {
      this.setState({
        showLoginComponent:true
      })
    }
  }
  
  hideLoginComponent(){
    this.setState({
      showLoginComponent:false
    })
  }

  toggleSignUp(){
    this.setState({
      signUp:!this.state.signUp
    })
  }

  buildLoginComponent(){
    if (this.state.showLoginComponent){
      if (this.state.signUp){
        return (
          <div className=''>
          <div onClick={this.hideLoginComponent} className='container-fluid insulfilm d-flex align-items-center justify-content-center'>
            <div onClick={event=>{event.stopPropagation()}} style={{width:700}} className='text-left'>
              <SignUp toggleSignUp={this.toggleSignUp} hideLoginComponent={this.hideLoginComponent}/>
            </div>
          </div>
        </div>
        )
      }
      return (
      <div className=''>
        <div onClick={this.hideLoginComponent} className='container-fluid insulfilm d-flex align-items-center justify-content-center'>
          <div onClick={event=>{event.stopPropagation()}} style={{width:700}} className='text-left'>
            <Login toggleSignUp={this.toggleSignUp} hideLoginComponent={this.hideLoginComponent}/>
          </div>
        </div>
      </div>
      )
    }
  }

  render(){ 
    return (
      <div className='container-fluid p-0'>
        {this.buildLoginComponent()}
        <h5 className='font-sm pt-3'>Start a new discussion</h5>
        <form onSubmit={this.sendPost}>
          <input required ref={this.titleRef} onChange={this.handleTitle} placeholder='Title' className='form-control form-control-lg p-2 border-0 mb-1 shadow-sm'></input>
          <textarea required ref={this.textRef} onChange={this.handleText} placeholder='Content' className='form-control form-control-sm border-0 shadow-sm'></textarea>
          <div className='pt-2'>
          {this.state.sendingPost ? <button style={{ minWidth: 70 }} type='submit' className='btn btn-primary shadow'><Loading type={'small'}/></button> : <SendButton/>}
          </div>
        </form>
      </div>
    );
  }
}