import React from "react";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import UserCalendar from "../components/profile/UserCalendar";
import SideMenu from "../components/sideMenu/sideMenu";

import config from "../config/config";
import getCookie from "../utils/getCookie";

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.hideLoginComponent = this.hideLoginComponent.bind(this);
    this.showLoginComponent = this.showLoginComponent.bind(this);
    this.toggleSignUp = this.toggleSignUp.bind(this);
  }

  componentDidMount() {
    this.getProfile();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  getProfile() {
    const { userId } = this.props.match.params;
    let headers;

    if (getCookie("token")) {
      headers = {
        "Content-Type": "application/json",
        Authorization: getCookie("token")
          ? "Bearer " + getCookie("token")
          : null,
      };
    } else {
      headers = { "Content-Type": "application/json" };
    }

    fetch(config.backtAdress + "/getprofile?q=" + userId, {
      method: "get",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          userInfo: res.userInfo,
          loading: false,
        });
      });
  }

  showLoginComponent(props) {
    if (props && props.signUp) {
      this.setState({ signUpState: true });
    } else {
      this.setState({ signUpState: false });
    }
    this.setState({ loginState: true });
  }

  hideLoginComponent() {
    this.setState({
      loginState: false,
      requestMyVotes: true,
    });
  }

  toggleSignUp() {
    this.setState({
      signUpState: !this.state.signUpState,
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Navbar />
          <div className="p-nav"></div>
          <div className="row container-fluid p-0 m-0">
            {getCookie("token") ? <SideMenu /> : null}
            <div className="col">
              <div style={{ maxWidth: 900 }} className="container">
                Loading...
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!getCookie("token")) {
      return (
        <div>
          <Navbar
            loginState={this.state.loginState}
            signUpState={this.state.signUpState}
            showLoginComponent={this.showLoginComponent}
            hideLoginComponent={this.hideLoginComponent}
            toggleSignUp={this.toggleSignUp}
          />
          <div className="p-nav"></div>
          <h5 className="display-3 text-center">Private content</h5>
          <h5 className="display-4 text-center">You must be login</h5>
        </div>
      );
    }

    return (
      <div style={{ minWidth: 0 }}>
        <Navbar />
        <div className="p-nav"></div>
        <div className="row container-fluid p-0 pb-5 m-0">
          {getCookie("token") ? <SideMenu /> : null}
          <div className="col m-0 p-0 pr-2">
            <UserCalendar />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
