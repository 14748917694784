import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import NewGroupForm from '../components/newGroupForm'

export default class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { 
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className='p-nav main-content container-fluid'>
        <NewGroupForm/>
        </div>
        <Footer/>
      </div>
    )
  }
}