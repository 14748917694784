import React from 'react';
import getCookie from '../utils/getCookie';
import LoginComponent from '../components/login';
import Navbar from '../components/navbar';
import ProfileCard from '../components/profileCard';
import Footer from '../components/footer';

export default class Account extends React.Component{
    render(){
      if (getCookie('token')){
        return (
          <div>
            <Navbar/>
            <div style={{maxWidth:800}} className='container-fluid p-1'>
              <ProfileCard/>
            </div>
            <Footer/>
          </div>
        )        
      }
      return (
        <LoginComponent/>
      )        
    }
}