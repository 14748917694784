import React from 'react'
import {AiOutlineArrowDown} from 'react-icons/ai'
const DownVote = props =>{
    const {voteValue,handleVote} = props
    if (voteValue < 0){
      return (
        <button onClick={(e)=>handleVote(e,-1)} className='btn btn-primary rounded-pill p-0'>
          <AiOutlineArrowDown style={{transform:'translateY(-2px)'}}/>
        </button>
      )
    }
    return (
      <button onClick={(e)=>handleVote(e,-1)} className='btn btn-light rounded-pill p-0'>
        <AiOutlineArrowDown style={{transform:'translateY(-2px)'}}/>
      </button>
    )
  }
export default DownVote