function arrayToTree (array,mainPostId){
    var map = {}, node, roots = [], i
    for (i = 0; i < array.length; i++) {
      map[array[i].postId] = i
      array[i].childrens = []
      array[i].loading = false
    }
  
    for (i = 0; i < array.length; i++) {
      node = array[i]
      if (node.postId !== mainPostId) {
        array[map[node.parent]].childrens.push(node)
        array[map[node.parent]].childrens.sort((a,b)=>{
          let priorityA = a.priority
          let priorityB = b.priority
          if (priorityA === priorityB){
            let dateA = new Date(a.createdAt)
            let dateB = new Date(b.createdAt)
            return dateA>dateB ? 1 : dateA<dateB ? -1 : 0;
          } else {
            if (!priorityA){priorityA = 0}
            if (!priorityB){priorityB = 0}
            return priorityA>priorityB ? -1 : priorityA<priorityB ? 1 : 0;
          }
        })
      } else {
        roots.push(node)
      }
    }
    return roots
  }
export default arrayToTree;