import React from 'react';
import {Link} from 'react-router-dom';
import config from '../config/config';
import setCookie from '../utils/setCookie';
import getCookie from '../utils/getCookie';
import PodcastLogoMD from '../images/logo-face-typo-transparent-md.png';
import { store } from 'react-notifications-component';
import ReactNotification from 'react-notifications-component';

export default class Account extends React.Component{
    constructor(props){
        super(props);
        this.state={}
        this.sendRegisterForm = this.sendRegisterForm.bind(this)
    }

    createNotification = (title,message,type) => {
      store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    };
  

    handdleInputs(key,value){
        let object = {}
        object[key] = value
        this.setState(object)
    }

    sendRegisterForm(e){
        e.preventDefault();
        if (this.state.password !== this.state.passwordConfirm){
          this.createNotification("Falha de cadastro","Confirmação de senha errada","danger")
          return null
        }
        fetch(config.backtAdress+'/newUser',{
            method:'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body:JSON.stringify({
                email:this.state.email,
                password:this.state.password,
                name:this.state.name,
                lastname:this.state.lastname
            })
        })
        .then(res=>res.json())
        .then(res=>{
            if (res.error){
                if (res.error === 'E-mail already used'){
                  this.createNotification("Falha de cadastro","Este e-mail está sendo utilizado por outro usuário.","danger")
                }
                return null
            }
            setCookie('token',res.token);
            setCookie('name',res.newUser.name);
            setCookie('lastname',res.newUser.lastname);
            setCookie('email',res.newUser.email);
            window.location.reload(false);
        })
    }

    render(){
        if (getCookie('token')){
            window.location.href = '/account'
            return null 
        }
        return (
          <div className='bg-light pb-5'>
            <ReactNotification/>
            <div style={{maxWidth:545}} className='container-fluid text-center'>
              <div className='display-4 text-left pb-2'>Cadastro</div>  
                <div className='bg-white rounded container-fluid shadow pr-5 pl-5 pb-5'>
                  <Link to={'/'}>
                  <img style={{maxHeight:180}} alt={'logo'} src={PodcastLogoMD}></img>
                  </Link>
                <div className='p-0'>
                <form onSubmit={this.sendRegisterForm} className='mb-5 form-group'>
                  <div className='text-left'>Nome</div>
                  <input onChange={(e)=>this.handdleInputs('name',e.target.value)} type='text' className='form-control' required></input>
                  <div className='text-left mt-4'>Sobrenome</div>
                  <input onChange={(e)=>this.handdleInputs('lastname',e.target.value)} type='test' className='form-control' required></input>
                  <div className='text-left mt-4'>E-mail</div>
                  <input onChange={(e)=>this.handdleInputs('email',e.target.value)}  type='email' className='form-control' required></input>
                  <div className='text-left mt-4'>Senha</div>
                  <input onChange={(e)=>this.handdleInputs('password',e.target.value)}  type='password' className='form-control' required></input>
                  <div className='text-left mt-4'>Confirmar Senha</div>
                  <input onChange={(e)=>this.handdleInputs('passwordConfirm',e.target.value)}  type='password' className='form-control' required></input>
                  <button className='btn shadow p-3 mt-5 btn-block btn-primary'>Criar conta</button>
                </form>
                <span>
                  Já é nosso aluno? <Link to={'/account'}>Acessar</Link>
                </span>
                </div>
              </div>
            </div>
          </div>
        )
    }
}