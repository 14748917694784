import React from 'react';
import {Link} from 'react-router-dom';
import config from '../config/config';
import getCookie from '../utils/getCookie';
import PodcastLogoMD from '../images/logo-face-typo-transparent-md.png';
import { store } from 'react-notifications-component';
import ReactNotification from 'react-notifications-component';

export default class PasswordRecover extends React.Component{
    constructor(props){
        super(props);
        this.state={};
        this.sendRegisterForm = this.sendRegisterForm.bind(this);
    }

    createNotification = (title,message,type) => {
      store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    };
  

    handdleInputs(key,value){
        let object = {}
        object[key] = value
        this.setState(object)
        console.log(this.state)
    }

    sendRegisterForm(e){
        e.preventDefault();
        let urlParams = new URLSearchParams(window.location.search);
        if (this.state.password !== this.state.passwordConfirm){
          this.createNotification("Falha de recuperação","Confirmação de senha errada","danger")
          return null
        }
        console.log(urlParams.get('e'))
        fetch(config.backtAdress+'/resetpassword',{
            method:'post',
            headers: {
                'Content-Type': 'application/json'
              },
            body:JSON.stringify({
                email:urlParams.get('e'),
                password:this.state.password,
                token:urlParams.get('t'),
            })
        })
        .then(res=>res.json())
        .then(res=>{
            if (res.error){
                if (res.error === 'E-mail already used'){
                  this.createNotification("Falha de recuperação",res.error,"danger")
                  return null
                }
                if (res.error === 'Invalid Token' || res.error === 'Token expired'){
                  this.createNotification("Falha de recuperação","Token inválido","danger")
                  return null
                }
                if (res.error === 'Missing field'){
                  this.createNotification("Falha de recuperação","São necessários um token e uma nova senha para continuar","danger")
                  return null
                }
                if (res.error === 'User not found'){
                  this.createNotification("Falha de recuperação","Usuário não encontrado","danger")
                  return null
                }
                return null   
            }
            window.location.href = '/account'
        })
    }

    render(){
        if (getCookie('token')){
            window.location.href = '/account'
            return null 
        }
        return (
          <div style={{minHeight:'100vh'}} className='pt-5 bg-colored pb-5'>
            <ReactNotification/>
            <div style={{maxWidth:545}} className='container-fluid text-center'>
              <div style={{fontSize:42}} className='display-4 text-left text-white pb-2'>Recuperar senha</div>
                <div className='bg-white rounded container-fluid shadow pr-5 pl-5 pb-5'>
                  <Link to={'/'}>
                  <img alt='logo' style={{maxHeight:180}} src={PodcastLogoMD}></img>
                  </Link>
                <div className='p-0'>
                <form onSubmit={this.sendRegisterForm} className='mb-5 form-group'>
                  <div className='text-left mt-4'>Nova Senha</div>
                  <input onChange={(e)=>this.handdleInputs('password',e.target.value)}  type='password' className='form-control' required></input>
                  <div className='text-left mt-4'>Confirmar Senha</div>
                  <input onChange={(e)=>this.handdleInputs('passwordConfirm',e.target.value)}  type='password' className='form-control' required></input>
                  <button className='btn shadow p-3 mt-5 btn-block btn-primary'>Alterar senha</button>
                </form>
                </div>
              </div>
            </div>
          </div>
        )
    }
}