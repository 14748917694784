import React from 'react'

import Navbar from '../components/navbar'
import MyTimeline from '../components/timeline/myTimeline'
import TrendingDiscutions from '../components/timeline/trendingDiscutions'
import TrendingGroups from '../components/timeline/trendingGroups'
import Header from '../components/homePage/header'
import SideMenu from '../components/sideMenu/sideMenu'

import getCookie from '../utils/getCookie'
//import SendPostAtHome from '../components/sendPostAtHome'

export default class Home extends React.Component{
  constructor (props){
    super(props)
    this.state = {}
    this.hideLoginComponent = this.hideLoginComponent.bind(this)
    this.showLoginComponent = this.showLoginComponent.bind(this)
    this.toggleSignUp = this.toggleSignUp.bind(this)
    this.turnOffRequestMyVotes = this.turnOffRequestMyVotes.bind(this)
  }

  showLoginComponent(props){
    if (props && props.signUp){
      this.setState({signUpState:true})
    } else {
      this.setState({signUpState:false})
    }
    this.setState({loginState:true})
  }
  hideLoginComponent(){
    this.setState({
      loginState:false,
      requestMyVotes:true
    })
  }

  toggleSignUp(){
    this.setState({
      signUpState:!this.state.signUpState
    })
  }

  turnOffRequestMyVotes(){
    this.setState({
      requestMyVotes:false
    })
  }

  render (){
    return (
      <div className='bg-light'>
        <div style={{zIndex:999,position:'fixed'}}>
          <Navbar 
          loginState={this.state.loginState}
          signUpState={this.state.signUpState}
          showLoginComponent={this.showLoginComponent}
          hideLoginComponent={this.hideLoginComponent}
          toggleSignUp = {this.toggleSignUp}
          />
        </div>
        {getCookie('token') ? <div className='p-nav bg-light'></div> : <Header/>}
        <div style={{maxWidth:900}} className='container-fluid bg-light p-0'>
          <div  className='row container-fluid p-0 m-0'>
            {getCookie('token') ?  <SideMenu/> : null}
            <div className='col p-0'>
              <div className='bg-light'>
                <div className='container-fluid main-content'>
                  <MyTimeline 
                    showLoginComponent={this.showLoginComponent}
                    turnOffRequestMyVotes={this.turnOffRequestMyVotes}
                    requestMyVotes={this.state.requestMyVotes}
                  />
                  {getCookie('token') ? null : <TrendingDiscutions 
                      showLoginComponent={this.showLoginComponent}
                      turnOffRequestMyVotes={this.turnOffRequestMyVotes}
                      requestMyVotes={this.state.requestMyVotes}
                  />}
                  <TrendingGroups/>
                </div>        
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}