import React from 'react'
import config from '../config/config.json'
import setCookie from '../utils/setCookie'
import { store } from 'react-notifications-component'
import ReactNotification from 'react-notifications-component'

const loginBackground = {
  minHeight:100,
  alignItems:'center',  
  backgroundSize:'cover',
  backgroundRepeat:'no-repeat',
  backgroundPosition:'60% 60%',
}

export default class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.sendLoginForm = this.sendLoginForm.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
  }

  handdleInputs(key, value) {
    let object = {}
    object[key] = value
    this.setState(object)
  }

  createNotification = (title, message, type) => {
    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  };

  sendLoginForm(e) {
    e.preventDefault()
    fetch(config.backtAdress + '/authenticate', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          if (res.error === 'Fail to authenticate') {
            this.createNotification("Falha de acesso ;-;", "Senha ou usuário incorreto", "danger")
          }
          return null
        }
        setCookie('token', res.token);
        setCookie('name', res.user.name);
        setCookie('userId', res.user._id);
        setCookie('lastname', res.user.lastname);
        setCookie('email', res.user.email);
        this.props.hideLoginComponent()
      })
  }

  forgotPassword(e){
    e.preventDefault()
    fetch(config.backtAdress + '/forgotpassword',{
      method:'post',
      headers:{
        'Content-Type':'application/json'
      },
      body: JSON.stringify({
        email:this.state.email
      })
    })
    .then(res=>res.json())
    .then(res=>{
      if(res.sucess){
        this.setState({sendedLink:true})
      } else {
        if (res.error === 'User not found'){
          this.createNotification("Erro na recuperação de senha","Email inválido","danger")
        } 
      }
    })
  }

  render() {
    if (this.state.resetpassword){
      if (this.state.sendedLink){
        return (
          <div className='container-fluid'>
          <ReactNotification />
            <div className='container-fluid p-0 text-center'>
              <div className='bg-white rounded-lg container-fluid shadow p-3'>
              <div className='text-primary text-md pb-2'>Caravana</div>
              <div>
              </div>
              <div style={{fontSize:26}} className='p-4 display-4'>Link de recuperação enviado com sucesso</div>
              <div style={{fontSize:22}} className='p-2 display-4'>Verifique seu e-mail</div>
              <button onClick={(e)=>{
                  this.handdleInputs('resetpassword',false)
                  e.preventDefault()}} className='text-sm m-0 p-0 pt-3 btn btn-link'>
                  Voltar
            </button>
            </div>
          </div>
        </div>
        )
      }
      return (
        <div className='container-fluid'>
          <ReactNotification />
          <div className='container-fluid p-0 text-center'>
            <div className='bg-dark rounded-lg container-fluid shadow p-3'>
              <div className='text-primary text-md pb-2'>Caravana</div>
              <div>
                <form onSubmit={this.forgotPassword} className='mb-5 form-group'>
                  <div className='text-left text-white'>E-mail</div>
                  <input id='email' onChange={(e) => this.handdleInputs('email', e.target.value)} type='email' className='form-control' required></input>
                  <button className='btn shadow p-3 mt-3 btn-block btn-secondary text-white'>Enviar link de recuperação</button>
                </form>
                <button onClick={(e)=>{
                      this.handdleInputs('resetpassword',false)
                      e.preventDefault()}} className='text-sm m-0 p-0 btn btn-link'>
                      Voltar
                </button>
              </div>
            </div>
        </div>
      </div>
      )
    }
    return (
      <div className='container p-0'>
        <ReactNotification />
        <div className='container p-2 text-center '>
          <div className='bg-white rounded-lg shadow ease-in-out container-fluid p-0 m-0 border-primary row'>
            <div style={loginBackground} className='col-sm shadow-sm panel'>
              <div className='text-md p-3 mt-5 text-white '>Caravana</div>
            </div>
            <div style={{minHeight:480}}  className='col-sm p-2'>
            <div className='pt-3 text-secondary pt-5'>Login</div>
              <div>
                <form onSubmit={this.sendLoginForm} className='form-group'>
                  <div className='text-left'>E-mail</div>
                  <input id='email' onChange={(e) => this.handdleInputs('email', e.target.value)} type='email' className='form-control' required></input>
                  <div className='text-left mt-2'>Password</div>
                  <input id='password' onChange={(e) => this.handdleInputs('password', e.target.value)} type='password' className='form-control' required></input>
                  <div className='container-fluid p-0 text-left'>
                    <div onClick={(e)=>{
                      this.handdleInputs('resetpassword',true)
                      e.preventDefault()}} style={{fontSize:13}} className='text-sm m-0 p-0 btn btn-link text-dark'>
                      Forget my password
                    </div>
                  </div>
                  <button className='btn shadow p-2 mt-1 btn-block btn-secondary'>Login</button>
                </form>
                <div className='mt-2'>
                  Not a member yet?
                </div>
                <button className='btn btn-outline-secondary mt-2 p-0 pr-1 pl-1 rounded' onClick={this.props.toggleSignUp}>Sing up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}