import React from 'react';
import config from '../config/config';
import getCookie from '../utils/getCookie';
import { Link } from 'react-router-dom';
import {BsPencil} from 'react-icons/bs';

export default class ProfileCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingProfile: true,
    }
  }

  componentWillMount() {
    this.getProfile()
  }

  getProfile() {
    if (getCookie('token')) {
      fetch(config.backtAdress + '/userRequest', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getCookie('token')
        },
        body: JSON.stringify({
          query: "getProfile"
        })
      })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          let profileDetails = res.profileDetails;
          profileDetails.totalCompletedTasks = res.totalCompletedTasks
          profileDetails.totalInscribes = res.totalInscribes
          profileDetails.totalAnswers = res.totalAnswers
          this.setState({
            profileDetails: profileDetails,
            loadingProfileDetails: false
          })
        }
      })
    }
  }

  buildUserStats(){
    if (!this.state.profileDetails){
      return (
        <div className='m-2 spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      )
    }
    return (
      <div className='row'>
        <div className='col p-3'>
          <div style={{fontSize:24,fontStyle:'bold'}}><b>{this.state.profileDetails.totalInscribes}</b></div>
          <div style={{fontSize:12,fontStyle:'italic'}}>Cursos inscritos</div>
        </div>
        <div className='col p-3'>
          <div style={{fontSize:24,fontStyle:'bold'}}><b>{this.state.profileDetails.totalCompletedTasks}</b></div>
          <div style={{fontSize:12,fontStyle:'italic'}}>Aulas concluídas</div>
        </div>
        <div className='col p-3'>
          <div style={{fontSize:24,fontStyle:'bold'}}><b>{this.state.profileDetails.totalAnswers}</b></div>
          <div style={{fontSize:12,fontStyle:'italic'}}>Questões respondidas</div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div style={{maxWidth:600}} className='pt-5 m-0 p-0 container-fluid'>
            <div className='col pt-3 pr-4 pl-4 border rounded shadow-sm'>
              <h1 style={{fontSize:42,transform:'translateX(-8px)'}} className='display-1'>{getCookie('name') + ' ' + getCookie('lastname')}</h1>
              <h1 style={{fontSize:20}} className='display-1'>{getCookie('email')}</h1>
              <div className='text-right'>
                <Link to='/account/profile' style={{fontSize:15}}>Editar perfil <BsPencil style={{transform:'translateY(-2px)'}}/></Link>
              </div>
            </div>
            <div className='col mt-2 border rounded shadow-sm pr-4 pl-4'>
              {this.buildUserStats()}
            </div>
        </div>
        
      </div>
    )
  }
}