import React from 'react'

const headerStyle = props => {
  return {
    minHeight:450,
    //backgroundImage:`url("${headerScene}")`,
    backgroundRepeat: 'no-repeat',
    //backgroundAttachment: 'fixed',
    backgroundSize: '50% auto',
    backgroundPosition: 'left bottom',
  }
}

function Header() {
  return (
    <div style={headerStyle()} className='bg-primary d-flex align-items-center justify-content-end mb-5'>
        <div style={{maxWidth:400, fontSize:82}} className='pr-5 pt-5 text-dark text-right text-shadow font-bold'> Welcome to Caravana</div> 
    </div>
  );
}
export default Header;