import React from 'react'
import follow from '../../utils/userRequest/follow'
import { GiOldWagon } from 'react-icons/gi'
const FollowButton = props => {
  const { group, showLoginComponent,handleFollowingGroup } = props
  if (group.following) {
    return (<button onClick={() => {
      handleFollowingGroup()
      follow({
        reference: group._id,
        active: 2,
        showLoginComponent: showLoginComponent
      })
    }} className='btn btn-secondary btn-sm p-0 pl-1 pr-1'>
      <GiOldWagon style={{ transform: 'translateY(-3px)', fontSize: 21 }} />
      <div className='d-inline pl-1'>Following</div>
    </button>
    )
  }
  return (
    <button onClick={() => {
      handleFollowingGroup()
      follow({
        reference: group._id,
        active: 1,
        showLoginComponent: showLoginComponent
      })
    }} className='btn btn-primary btn-sm p-0 pl-1 pr-1'>
      <GiOldWagon style={{ transform: 'translateY(-3px)', fontSize: 21 }} />
      <div className='d-inline pl-1'>Follow</div>
    </button>
  )
}
export default FollowButton