import React from 'react';
import Modal from 'react-bootstrap/Modal';
import getCookie from '../../utils/getCookie';
import config from '../../config/config.json';

let eventGuid = 0;
export function createEventId() {
  return String(eventGuid++)
}

export default class EditEventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmInputState: 0
    };

    this.saveTask = this.saveTask.bind(this);

    this.handleConfirmInput = this.handleConfirmInput.bind(this);

  }

  handleConfirmInput = (event) => {
    this.setState({
      confirmInputState: Number(event.target.value)
    })
  }

  saveTask = async () => {
    let taskObject
    let newStart = this.props.selectedEventInfo.event.startStr
    let newEnd = this.props.selectedEventInfo.event.endStr

    if (this.state.confirmInputState === 0) {
      
      taskObject = {
        exceptionOf: this.props.selectedEvent._id,
        title: this.props.selectedEventInfo.event.title,
        start: newStart,
        end: newEnd,
        allDay: this.props.selectedEventInfo.event.allDay,
      }

    } else if (this.state.confirmInputState === 1) {

      taskObject = {
        taskId: this.props.selectedEvent._id,  
        startTime: newStart.split('T')[1],
        endTime: newEnd.split('T')[1],
        allDay: this.props.selectedEventInfo.event.allDay,
      }

    }

    fetch(config.backtAdress + '/editTask', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getCookie('token')
      },
      body: JSON.stringify(taskObject)
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
      })

  }

  render() {
    if (!this.props.selectedEvent) return null

    return (
      <div>
        <Modal show={this.props.show} onHide={() => { this.props.toggleModal(); this.props.selectedEventInfo.revert() }}>
          <Modal.Header>
            <Modal.Body className='container-fluid p-0 m-0'>
              <div className='w-100 border-0 p-1 m-0'>Edit recurring event</div>
            </Modal.Body>
          </Modal.Header>
          <Modal.Body className='container-fluid pr-3 pl-3 pt-2 pb-2'>
            <div className="form-check">
              <input onClick={this.handleConfirmInput} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="0" defaultChecked></input>
              <label className="form-check-label" htmlFor="exampleRadios1">
                Just this event
                </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="1"></input>
              <label onClick={this.handleConfirmInput} className="form-check-label" htmlFor="exampleRadios2">
                All events
                </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-light' onClick={() => { this.props.toggleModal(); this.props.selectedEventInfo.revert() }}>Cancel</button>
            <button className='btn btn-primary' onClick={() => this.saveTask()}>Save</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

//fc-timegrid-event fc-v-event fc-timegrid-event-condensed fc-event fc-event-draggable fc-event-resizable fc-event-start fc-event-end fc-event-past fc-event-today