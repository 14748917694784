import React from 'react'
import Loading from '../components/loadingAnimation'

export default class BuildLoadMoreButton extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
  }

  componentWillMount(){
    this.props.child.childrens.map((child)=>{
      if (child.lastPost){
        this.setState({hasLastPost:true})
      }
      return null
    })
  }

  render(){
    const child = this.props.child
    const loading = this.props.loading
    let offset = child.childrens.length

    if (offset > 0){
      offset = offset-1
    }    

    if (child.hasComments && !this.state.hasLastPost){     
        if (loading){
          return (
            <Loading type='sm'/>
          )
        }
        return (
          <button key={child.postId+'_load'} className='p-0 btn btn-link text-primary no-decoration text-center'>
            {
            <h5 onClick={async ()=>{
              this.props.loadMoreComments({
              postId:child.postId,
              offset:offset
              })
              }
            }
              className='font-smaller text-secondary'>
                Continue...
            </h5>
            }
          </button>
        )
    }
    return null
  }
}