import React from 'react'
import { Link } from 'react-router-dom'
import eraseCookie from '../utils/eraseCookie'
import getCookie from '../utils/getCookie'
import Login from '../components/login'
import SignUp from '../components/signUp'

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginComponent:false,
      logged:false,
    }
    this.hideLoginComponent = this.hideLoginComponent.bind(this)
    this.logout = this.logout.bind(this) 
  }

  componentDidMount(){
    this.updateLoginButton()
  }

  updateLoginButton(){
    if(getCookie('token')){
      this.setState({logged:true})
    } else {
      setTimeout(()=>{this.updateLoginButton()},100)
    }
  }

  hideLoginComponent(){
    this.setState({
      showLoginComponent:false
    })
  }

  buildLoginComponent(){
    if (this.props.loginState){
      if (this.props.signUpState){
        return (
          <div className=''>
          <div onClick={this.props.hideLoginComponent} className='container-fluid insulfilm d-flex align-items-center justify-content-center'>
            <div onClick={event=>{event.stopPropagation()}} style={{width:700}} className='text-left'>
              <SignUp toggleSignUp={this.props.toggleSignUp} hideLoginComponent={this.props.hideLoginComponent}/>
            </div>
          </div>
        </div>
        )
      }
      return (
      <div className=''>
        <div onClick={this.props.hideLoginComponent} className='container-fluid insulfilm d-flex align-items-center justify-content-center'>
          <div onClick={event=>{event.stopPropagation()}} style={{width:700}} className='text-left'>
            <Login toggleSignUp={this.props.toggleSignUp} hideLoginComponent={this.props.hideLoginComponent}/>
          </div>
        </div>
      </div>
      )
    }
  }


  buildLoginButton() {
    if (this.state.logged) {
      return (
        <div>
          {this.buildAccountMenu()}
        </div>
      )
    }
    return (
      <div>
        <button onClick={this.props.showLoginComponent} className='ml-2 p-0 pl-2 pr-2 btn btn-outline-secondary' style={{ fontSize: 18 }}>Login</button>
        <button onClick={()=>{this.props.showLoginComponent({signUp:true})}} className='ml-2 p-0 pl-2 pr-2 btn btn-primary' style={{ fontSize: 18 }}>Sign up</button>
      </div>
    )
  }


  buildAccountMenu() {
    return (
          <div className="dropdown">
            <button className="btn btn-secondary text-grey-dark dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Welcome <b className='mr-2'>{getCookie('name')}</b>
            </button>
            <div className="dropdown-menu dropdown-menu-lg-right" aria-labelledby="dropdownMenu2">
              <button className="dropdown-item" type="button">Account</button>
              <button className="dropdown-item" type="button">Preferences</button>
              <div className="dropdown-divider"></div>
              <Link to='/newgroup' className="dropdown-item" type="button">Start a new group</Link>
              <div className="dropdown-divider"></div>
              <button onClick={this.logout} className="dropdown-item" type="button">Logout</button>
            </div>
          </div>
    )
  }

  logout(e) {
    e.preventDefault()
    eraseCookie('token')
    eraseCookie('name')
    eraseCookie('lastname')
    eraseCookie('email')
    eraseCookie('userId')
    window.location.reload(false)
  }

  render() {
    return (
      <div style={{ zIndex: 999, position: "fixed" }} className='container-fluid bg-white shadow-sm'>
        {this.buildLoginComponent()}
        <div className='row p-3'>
          <div className='col d-flex align-items-center'>
            <Link to={'/'} className='text-dark no-decoration ' style={{ fontSize: 22 }}>
              Caravana
            </Link>
          </div>
          <div className='col pt-1 text-right'>
            {this.buildLoginButton()}
          </div>
        </div>
      </div>
    )
  }
}