import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import JuleProfileIMG from '../images/jule-profile.jpg';

const juleProfileCircle = {
  minWidth:'250px',
  maxWidth:'250px',
  minHeight:'250px',
  maxHeight:'250px',
  margin:0,
  padding:0,
  alignItems:'center',
  backgroundImage:`url(${JuleProfileIMG})`,
  backgroundSize:'cover',
  backgroundRepeat:'no-repeat',
  backgroundPosition:'40% 60%',
}

function about() {
  return (
    <div>
      <Navbar/>
      <div style={{maxWidth:1000}}  className='container-fluid pb-5'>
        <div className='display-4 text-center pt-5'>Sobre</div>
          <div className='pb-2 text-justify'>
            <div className='row p-5'>
              <div className='col-sm-4 shadow rounded-circle' style={juleProfileCircle}>
              </div>
              <div className='col-sm d-flex align-items-center'>
                <div className='p-3 text-justify'>
                  <b>Jule Santos</b> é médica, trabalha e é apaixonada por emergência desde 2010! Áreas de interesse são: paciente crítico, manejo da via aérea e educação médica. Idealizadora e fundadora da página Emergência Rules, criada em 24 de outubro de 2016, baseada no movimento FOAM (Free Open Access to Medical Education) com o intuito de difundir conhecimento médico de qualidade para o máximo de pessoas possível e assim tornar o mundo um lugar melhor. Supervisora do programa de Residência de Medicina de Emergência ESCS/DF, Preceptora do internato de CM da uniCEUB, Vice-Presidente ABRAMEDE – DF, Atualmente trabalha nas sala vermelha do Hospital Regional de Santa Maria, DF, e no Hospital Maria Auxiliadora, Gama, DF. Nas horas vagas escreve o blog e livros de ficção!
                </div>
              </div>
            </div>
          </div>
      </div>
      <Footer/>
    </div>
  );
}
export default about;
