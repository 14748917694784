import React from "react";
import config from "../config/config";
import { Link } from "react-router-dom";
import { BsPeopleCircle } from "react-icons/bs";
import { FaAngleLeft, FaAngleDown } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";

import SendReply from "../components/sendReply";
import SinglePost from "../components/singlePost";
import BuildLoadMoreButton from "../components/buildLoadMoreButton";
import DownVote from "../components/vote/downVote";
import UpVote from "../components/vote/upVote";
import DeletePostModal from "../components/deletePostModal";

import timeSince from "../utils/timeSince";
import getCookie from "../utils/getCookie";

function getLevel(parent, child) {
  let level = 0;
  while (child && parent !== child) {
    level++;
    child = child.parentNode;
  }
  return child ? level : 0;
}

export default class singlePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voteValue: 0,
      hasMyVote: 0,
      singlePostHover: "",
      mainCommentStyle: "",
      mainCommentMargin: "",
      showLoadMoreButton: false,
      showChildrens: true,
    };
    this.hideChildrens = this.hideChildrens.bind(this);
    this.showChildrens = this.showChildrens.bind(this);
    this.reloadChildPositions = this.reloadChildPositions.bind(this);
    this.singlePostRef = new React.createRef();
    this.handleVote = this.handleVote.bind(this);
    this.deleteThisPost = this.deleteThisPost.bind(this);
  }

  handleVote(e, buttonValue) {
    if (!getCookie("token")) {
      this.props.showLoginComponent();
      return null;
    }
    let voteValue;
    if (buttonValue === this.state.voteValue) {
      voteValue = 0;
    } else {
      voteValue = buttonValue;
    }
    this.setState({
      voteValue: voteValue,
    });
    this.sendVote(voteValue);
  }

  handleLoadMoreButton(postId) {
    let obj = {};
    obj[postId] = {
      loading: false,
    };
    this.setState(obj);
  }

  componentWillUpdate() {
    if (this.props.requestMyVotes) {
      this.getMyVote(this.props.post.postId);
      this.props.turnOffRequestMyVotes();
    }
  }

  componentDidMount() {
    this.getMyVote(this.props.post.postId);
    this.reloadChildPositions();
    if (this.props.mainComment) {
      this.setState({
        mainCommentStyle: "hover-light-out rounded-lg shadow-sm",
        mainCommentMargin: "mb-2",
      });
    }
  }

  getMyVote(postId) {
    fetch(config.backtAdress + "/userRequest", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookie("token"),
      },
      body: JSON.stringify({
        query: "getMyVote",
        postId: postId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          let hasMyVote = 0;
          if (res.myVote !== 0) {
            res.myVote > 0 ? (hasMyVote = -1) : (hasMyVote = 1);
          }
          this.setState({
            voteValue: res.myVote,
            hasMyVote: hasMyVote,
          });
        } else {
          console.log("error", res.err);
        }
      });
  }

  hideChildrens() {
    let parent = this.singlePostRef.current.children[0];
    let childrens = parent.getElementsByClassName("post-body");
    for (let child of childrens) {
      if (getLevel(parent, child) < 9) {
        child.style.display = "none";
      }
    }
    this.setState({ showChildrens: false });
  }

  showChildrens() {
    let parent = this.singlePostRef.current.children[0];
    let childrens = parent.getElementsByClassName("post-body");
    for (let child of childrens) {
      if (getLevel(parent, child) < 9) {
        child.style.display = "block";
      }
    }
    this.setState({ showChildrens: true });
  }

  reloadChildPositions() {
    let parent = this.singlePostRef.current.children[0];
    let childrens = parent.getElementsByClassName("post-body");
    let allHeights = [];
    for (let i = 0; i < childrens.length; i++) {
      if (getLevel(parent, childrens[i]) < 9) {
        allHeights.push({
          x: childrens[i].offsetLeft,
          y: childrens[i].offsetTop,
        });
      }
    }
    let obj = {
      allHeights: allHeights,
      parentPos: {
        x: parent.offsetLeft,
        y: parent.offsetTop,
      },
    };

    this.setState(obj);
  }

  sendVote(voteValue) {
    fetch(config.backtAdress + "/userRequest", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookie("token"),
      },
      body: JSON.stringify({
        query: "sendVote",
        postId: this.props.post.postId,
        voteValue: voteValue,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //console.log(res)
        } else {
          this.setState({ voteValue: 0 });
          console.log("error", res.err);
        }
      });
  }

  buildBorderPathToChildSupport() {
    if (this.state.allHeights) {
      return this.state.allHeights.map((childPos) => {
        if (childPos.y > 0) {
          return (
            <div
              key={childPos.y}
              style={{
                top: childPos.y,
                left: childPos.l,
                position: "absolute",
                transform: "translate3D(-5px,9px,0)",
              }}
            >
              <div
                className={`${this.state.showborderBottomDotted} p-0`}
                style={{ minWidth: 6, minHeight: 10, maxHeight: 10 }}
              ></div>
            </div>
          );
        }
        return null;
      });
    }
  }

  buildPostBody() {
    if (this.props.post.title) {
      return (
        <div className="pr-3">
          <div className="row p-0 m-0">
            <div style={{ maxWidth: 32 }} className="col p-0 m-0">
              <div
                style={{ maxWidth: 32 }}
                className="col p-0 pb-1 container-fluid text-center"
              >
                <div>
                  <UpVote
                    voteValue={this.state.voteValue}
                    handleVote={this.handleVote}
                  />
                </div>
                <div>
                  {this.props.post.pontuation +
                    this.state.voteValue +
                    this.state.hasMyVote}
                </div>
                <div>
                  <DownVote
                    voteValue={this.state.voteValue}
                    handleVote={this.handleVote}
                  />
                </div>
              </div>
            </div>
            <div style={{ zIndex: -1 }} className="col p-0 pt-2 m-0">
              <h5>
                <Link
                  to={`/post/${this.props.groupAdress}/${
                    this.props.post.postId
                  }/${this.props.post.title.replace(/ /g, "_").toLowerCase()}`}
                  className="text-break no-decoration"
                >
                  {this.props.post.title}
                </Link>
              </h5>
              <div className="pr-3 pb-2">
                <Link
                  to={`/post/${this.props.groupAdress}/${
                    this.props.post.postId
                  }/${this.props.post.title.replace(/ /g, "_").toLowerCase()}`}
                  className="text-break no-decoration"
                >
                  {this.props.post.text}
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link
              to={`/post/${this.props.groupAdress}/${
                this.props.post.postId
              }/${this.props.post.title.replace(/ /g, "_").toLowerCase()}`}
            >
              {this.props.post.shortened ? (
                <small className="text-green font-small text-center text-dark">
                  {" "}
                  Continue
                </small>
              ) : null}
            </Link>
          </div>
          {/*this.props.post.totalComments ? 
        <Link 
          to={`/post/${this.props.groupAdress}/${this.props.post.postId}/${this.props.post.title.replace(/ /g,"_").toLowerCase()}`}
          className='btn btn-link text-dark no-decoration p-0'
        >
          <div className='font-smaller'>{this.props.post.totalComments}{this.props.post.totalComments > 1 ? <span> Comments</span> : <span> Comment</span>} </div>
        </Link> : null*/}
        </div>
      );
    } else {
      return (
        <div
          className={`${this.state.showborderLeftDotted} ease-in-out container-fluid p-0 pl-2`}
        >
          <div style={{ marginLeft: 3 }}>
            {this.buildBorderPathToChildSupport()}
            <div>
              <div
                style={{ marginLeft: 3, whiteSpace: "pre-line" }}
                className="pr-3 text-break "
              >
                {this.props.post.text}
              </div>
            </div>
            <div className="row">
              <div style={{ maxWidth: 32 }} className="col">
                <UpVote
                  voteValue={this.state.voteValue}
                  handleVote={this.handleVote}
                />
              </div>
              <div
                style={{ maxWidth: 15, fontSize: 12 }}
                className="col p-0 m-0 text-center d-flex align-items-center justify-content-center"
              >
                {this.props.post.pontuation +
                  this.state.voteValue +
                  this.state.hasMyVote}
              </div>
              <div style={{ maxWidth: 32 }} className="col p-0 m-0">
                <DownVote
                  voteValue={this.state.voteValue}
                  handleVote={this.handleVote}
                />
              </div>
              <SendReply
                groupCode={this.props.post.groupCode}
                showLoginComponent={this.props.showLoginComponent}
                postId={this.props.post.postId}
                addNewPosts={this.props.addNewPosts}
              />
            </div>
            {this.props.post.childrens.map((child, key) => {
              return (
                <div key={child.postId}>
                  <SinglePost
                    showLoginComponent={this.props.showLoginComponent}
                    groupAdress={this.props.groupAdress}
                    post={child}
                    loadMoreComments={this.props.loadMoreComments}
                    loading={child.loading}
                    addNewPosts={this.props.addNewPosts}
                  >
                    <BuildLoadMoreButton
                      loadMoreComments={this.props.loadMoreComments}
                      parent={this.props.post}
                      child={child}
                      loading={child.loading}
                      key_={key}
                    />
                  </SinglePost>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  buildPost() {
    if (this.props.post) {
      return (
        <div
          style={{ bottom: 0 }}
          className="containerl-fluid p-0 pl-2 h-100 mb-3"
        >
          <div className="row container-fluid p-0 m-0">
            <div style={{ zIndex: 1 }} className="col p-0">
              <div
                style={{ transform: "translateX(-6px)" }}
                className="row container-fluid  p-0 m-0"
              >
                <div className="col p-0">
                  <div className="row container-fluid">
                    <div style={{maxWidth:15}} className="col p-0 text-left">
                      <div
                        id={"profileImg_" + this.props.post.ownerName}
                        className="d-inline text-left"
                      >
                        <BsPeopleCircle style={{ fontSize: 16 }} />
                      </div>
                    </div>
                    <div className='col p-0 text-left'>

                    <small className="text-sm font-sm">
                    {
                      <Link
                        className="ml-1"
                        to={`/profile/${this.props.post.ownerId}`}
                      >
                        <h5 className="d-inline font-smaller text-secondary">
                          {this.props.post.ownerName}
                        </h5>
                      </Link>
                    }
                  </small>
                  {
                    <Link
                      to={`/post/${this.props.groupAdress}/${
                        this.props.post.postId
                      }/${this.props.post.text
                        .replace(/ /g, "_")
                        .toLowerCase()}`}
                    >
                      <div className="d-sm-inline font-smaller text-secondary text-nowrap pl-1">
                        posted this{" "}
                        {timeSince(new Date(this.props.post.createdAt))}
                      </div>
                    </Link>
                  }

                  <Link to={`/group/${this.props.groupAdress}`}>
                    <div className="d-inline font-smaller text-secondary text-nowrap pl-1">
                      in {this.props.post.groupName}
                    </div>
                  </Link>


                    </div>
                  </div>
                  
                </div>
                <div className="col-1 p-0 text-right">
                  <div>
                    <div className="btn-group dropleft">
                      <button
                        className="btn btn-link text-dark p-0"
                        type="button"
                        id="postDropdownMenu"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <BsThreeDots />
                      </button>
                      <div className="dropdown-menu">
                        {this.props.post.ownerId === getCookie("userId") ? (
                          <button
                            type="button"
                            className="m-0 btn btn-light btn-block rounded-0 text-left"
                            data-toggle="modal"
                            data-target={`#delete_${this.props.post.postId}`}
                          >
                            Delete
                          </button>
                        ) : null}
                        <button
                          type="button"
                          className="m-0 btn btn-light btn-block rounded-0 text-left"
                        >
                          Report
                        </button>
                      </div>
                    </div>
                  </div>
                  <span className="text-right w-100">
                    {this.props.post.childrens &&
                    this.props.post.childrens.length > 0 ? (
                      this.state.showChildrens ? (
                        <FaAngleDown onClick={this.hideChildrens} />
                      ) : (
                        <FaAngleLeft onClick={this.showChildrens} />
                      )
                    ) : null}
                  </span>
                </div>
              </div>
              {this.buildPostBody()}
              {this.props.children}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  deleteThisPost() {
    fetch(config.backtAdress + "/userRequest", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookie("token"),
      },
      body: JSON.stringify({
        query: "deletePost",
        postId: this.props.post.postId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.props.removePostAndUpdateState(this.props.post.postId);
        } else {
          console.log("error", res.err);
        }
      });
  }

  render() {
    return (
      <div
        className="post-body"
        ref={this.singlePostRef}
        onMouseEnter={() => {
          if (this.props.post.totalComments > 0) {
            this.reloadChildPositions();
          }
        }}
        onMouseLeave={() => {}}
      >
        <div
          onMouseOver={(e) => {
            e.stopPropagation();
            let showborderLeftDotted = "";
            let showborderBottomDotted = "";
            if (this.props.post.totalComments > 0) {
              showborderLeftDotted = "border-left-dotted";
              if (this.state.showChildrens) {
                showborderBottomDotted = "border-bottom-dotted";
              }
            }
            this.setState({
              showborderLeftDotted: showborderLeftDotted,
              showborderBottomDotted: showborderBottomDotted,
              singlePostHover: "hover-light",
            });
          }}
          onMouseOut={() => {
            this.setState({
              showborderLeftDotted: "",
              showborderBottomDotted: "",
              singlePostHover: "",
            });
          }}
          className={`container-fluid border-left-primary p-0 ${this.state.mainCommentMargin} ${this.state.singlePostHover} ${this.state.mainCommentStyle}`}
        >
          <DeletePostModal
            postTitle={this.props.post.title}
            postBody={this.props.post.text}
            dataTarget={`delete_${this.props.post.postId}`}
            confirmationAction={this.deleteThisPost}
          />
          {this.buildPost()}
        </div>
      </div>
    );
  }
}
