import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { GiTrashCan } from 'react-icons/gi';
import getCookie from '../../utils/getCookie';
import config from '../../config/config.json';

let eventGuid = 0;
export function createEventId() {
  return String(eventGuid++)
}

export default class EditEventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleNewTaskTitle = this.handleNewTaskTitle.bind(this);
    this.handleNewTaskDescription = this.handleNewTaskDescription.bind(this);
    this.saveTask = this.saveTask.bind(this);

  }

  handleDaysOfWeek = (weekDay) => {

    let auxHandleDaysOfWeek = this.props.selectedEvent.daysOfWeek ? this.props.selectedEvent.daysOfWeek : [];

    if (auxHandleDaysOfWeek.includes(String(weekDay))) {

      const index = auxHandleDaysOfWeek.indexOf(String(weekDay));
      if (index > -1) {
        auxHandleDaysOfWeek.splice(index, 1);
      }

    } else {
      auxHandleDaysOfWeek.push(String(weekDay));
    }    

    this.setState({
      handleDaysOfWeek: auxHandleDaysOfWeek
    })

  }

  
  handleNewTaskTitle(event) {
    this.setState({
      handleNewTaskTitle: event.target.value
    })
  }

  handleNewTaskDescription(event) {
    this.setState({
      handleNewTaskDescription: event.target.value
    })
  }

  removeTask(taskId) {
    if (getCookie('token')) {
      this.setState({ removingTask: true })
      fetch(config.backtAdress + '/removeTask', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getCookie('token')
        },
        body: JSON.stringify({
          taskId: taskId
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            console.log('Task removed!');
            this.props.selectedEventInfo.event.remove();
            this.props.toggleShowEditEventModal();
          } else {
            console.error(res.error);
          }
        })

    }

  }

  buildDaysOfWeekCheckBoxes() {
    let daysOfWeek = ['0', '1', '2', '3', '4', '5', '6']
    let daysOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return (
      <div className='row container'>
        {daysOfWeek.map((value, key) => {
          if (this.state.handleDaysOfWeek ? this.state.handleDaysOfWeek.includes(value) : this.props.selectedEvent.daysOfWeek.includes(value)) {
            return (
              <button key={key} className='btn active btn-light form-check col-sm' onClick={e => this.handleDaysOfWeek(value)}>
                <label className='form-check-label'>{daysOfWeekNames[value]}</label>
              </button>
            )
          } else {
            return (
              <button key={key} className='btn btn-light form-check col-sm' onClick={e => this.handleDaysOfWeek(value)}>
                <label className='form-check-label'>{daysOfWeekNames[value]}</label>
              </button>
            )
          }
        })}
      </div>
    )
  }

  saveTask = async () => {
    let taskObject
    //let calendarApi = this.calendarRef.current._calendarApi;
    taskObject = {
      taskId: this.props.selectedEvent._id,
      title: this.state.handleNewTaskTitle,
      start: this.state.handleNewTaskStart,
      end: this.state.handleNewTaskEnd,
      startTime: this.state.handleNewTaskStart ? this.state.handleNewTaskStart.split('T')[1] : undefined,
      endTime: this.state.handleNewTaskEnd ? this.state.handleNewTaskEnd.split('T')[1] : undefined,
      allDay: this.state.handleNewTaskAllDay,
      daysOfWeek: this.state.handleDaysOfWeek
    }  
    //console.log(taskObject)  
    //calendarApi.addEvent(taskObject);

    fetch(config.backtAdress + '/editTask', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getCookie('token')
      },
      body: JSON.stringify(taskObject)
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
      })

  }

  render(){
    if (!this.props.selectedEvent) return null

      return (
      <div>
        <Modal show={this.props.show} onHide={this.props.toggleShowEditEventModal}>

            <Modal.Header>
              <Modal.Body className='container-fluid p-0 m-0'>
                <input value={this.state.handleNewTaskTitle ? this.state.handleNewTaskTitle : this.props.selectedEvent.title} onChange={this.handleNewTaskTitle} className='w-100 form-control form-control-lg border-0 p-1 m-0'></input>
              </Modal.Body>
            </Modal.Header>
            <Modal.Body className='container-fluid pr-3 pl-3 pt-2 pb-2'>
              <textarea placeholder='Description' onChange={this.handleNewTaskDescription} className='w-100 form-control border-0 p-1 m-0'></textarea>
              <div className='pt-2 pb-2'>
                <AiOutlineClockCircle className='mr-1' style={{ transform: 'translateY(-2px)' }} />
                <div className='d-inline pr-4'>
                  Start: {this.props.selectedEvent.start}
                </div>
                <AiOutlineClockCircle className='mr-1' style={{ transform: 'translateY(-2px)' }} />
                <div className='d-inline'>
                  End: {this.props.selectedEvent.end}
                </div>
              </div>
              Allday {String(this.props.selectedEvent.allDay)}
              <div className='small pt-2'>Recurring event</div>
              {this.buildDaysOfWeekCheckBoxes()}
            </Modal.Body>

            <Modal.Footer>
              <div className='row container-fluid p-0 m-0'>
                <div className='col-8 d-flex justify-content-start p-0 m-0'>
                  <button className='btn btn-light' onClick={()=>{this.removeTask(this.props.selectedEvent._id)}}><GiTrashCan/></button>
                </div>
                <div className='col-2 d-flex justify-content-end p-0 m-0'>
                <button className='btn btn-light ' onClick={this.props.toggleShowEditEventModal}>Cancel</button>
                </div>
                <div className='col-2 d-flex justify-content-end p-0 m-0'>
                  <button className='btn btn-primary' onClick={this.saveTask}>Save</button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
      </div>
    )
  }
}