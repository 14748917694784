import React from 'react'
import config from '../../config/config'
import SinglePost from '../../components/singlePost'

import hasLastPost from '../../utils/hasLastPost'
import removePost from '../../utils/posts/removePost'

export default class trendingDiscutions extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      loadingInscribes:true,      
    }
    this.removePostAndUpdateState = this.removePostAndUpdateState.bind(this)
  }

  componentDidMount() { 
    this.postsStateListener()   
    this.getTrendingPosts()
    window.addEventListener('scroll', ()=>this.detectScrollAtBottom())
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', ()=>this.detectScrollAtBottom());
  }

  removePostAndUpdateState(postIdToRemove){
    const newPostArray = removePost({
      oldPostArray:this.state.postsArray,
      postIdToRemove:postIdToRemove
    })
    console.log(newPostArray)
    this.setState({
      postsArray:newPostArray,
      requestSortedtrendingPosts:true,
    })
  }

  getTrendingPosts(){
    fetch(config.backtAdress+'/trendingPosts?offset=0',
    {
      method:'get',
      headers:{
        'Content-Type':'application/json'
      }
    })
    .then(res=>res.json())
    .then(res=>{
      this.setState({
        postsArray:res.trendingPosts,
        requestSortedtrendingPosts:true,
      })
    })
  }

  postsStateListener(){
    if (this.state.requestSortedtrendingPosts){
      this.sortTrendingPosts()
    }
    setTimeout(()=>{
      this.postsStateListener()
    },500)
  }
  
  detectScrollAtBottom(){
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight -1) {
      //this.loadMoretrendingPosts()
    }
  }

  async loadMoretrendingPosts(){
    if (!this.state.postsArray || this.state.postsArray.length < 1 || !this.state.trendingPosts){
      this.setState({
        requestSortedtrendingPosts:true,
      })
      return null
    }

    if (await hasLastPost(this.state.postsArray)){
      return null
    }
    
    let offset = this.state.trendingPosts.length - 1
    offset = offset-1    
    this.setState({      
      loadingMoretrendingPosts:true,
    })
    fetch(config.backtAdress + '/trendingPosts?offset='+offset,{
      method:'get',
      headers:{
        'Content-Type':'application/json'
      }
    })
    .then(res=>res.json())
    .then(res=>{
      if (res.success){
        this.setState({requestSortedtrendingPosts:true})
        this.addNewPosts(res.trendingPosts)
      } else {
        console.log(res.err)
      }
    })
  }

  buildAlltrendingPosts(){
    return (
    <div>
      <h5 className='font-sm'>Bests posts</h5>
      {this.state.trendingPosts.map((post)=>{
        return (
          <SinglePost 
            showLoginComponent={this.props.showLoginComponent}
            turnOffRequestMyVotes={this.props.turnOffRequestMyVotes}
            requestMyVotes={this.props.requestMyVotes}
            removePostAndUpdateState={this.removePostAndUpdateState}
            className='' 
            key={post.postId}
            post={post}
            mainComment={true}
            groupAdress={post.groupAdress}
            childrens={[]}
            />
        )
      })}
    </div>
    )
  }

  sortTrendingPosts(){
    if (this.state.postsArray && this.state.postsArray.length < 1){
      this.setState({
        requestSortedtrendingPosts:false,
        trendingPosts:[],
        loadingTrendingPosts:false,
        loadingMoretrendingPosts:false,
      })
    }
    if (this.state.postsArray && this.state.postsArray.length > 0){
      let trendingPosts = this.state.postsArray.sort((a,b)=>{
        let priorityA = a.pontuation
        let priorityB = b.pontuation
        if (priorityA === priorityB){
          let dateA = new Date(a.createdAt)
          let dateB = new Date(b.createdAt)
          return dateA>dateB ? 1 : dateA<dateB ? -1 : 0;
        } else {
          if (!priorityA){priorityA = 0}
          if (!priorityB){priorityB = 0}
          return priorityA>priorityB ? -1 : priorityA<priorityB ? 1 : 0;
        }
      })
      this.setState({
        requestSortedtrendingPosts:false,
        trendingPosts:trendingPosts,
        loadingTrendingPosts:false,
        loadingMoretrendingPosts:false,
      })
    }
  }

  render(){ 
    if (this.state.loadingTrendingPosts || !this.state.trendingPosts){
      return (
        <div className='m-5 spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      )
    }
    if (this.state.trendingPosts.length < 1){
      return null
    }
    return (
      <div className='container pb-5'>
        <div>
          {this.buildAlltrendingPosts()}
        </div>
      </div>
    );
  }
}