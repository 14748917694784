import React from 'react';
import getCookie from '../../utils/getCookie';
import config from '../../config/config.json';
import './dailyChard.css';


function hideAllTooltips() {
  const names = ["tooltip", "popover"];
  for (const name of names) {
      [...document.getElementsByClassName(name)].forEach(element => {
        window.$(element).data(`bs.${name}`).hide();
      });
  }
}

export default class DailyChard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reshaped_events: [],
      chart: [],
      days: [],
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      weeksToShow: 52
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentDidMount() {

    this.getCompletedTasks();

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions);

    setTimeout(()=>{window.$('[data-toggle="tooltip"]').tooltip();  },1000)

  }

  componentWillUnmount() {

    window.removeEventListener('resize', this.updateWindowDimensions);

    window.$('[data-toggle="tooltip"]').tooltip();  
  }


  updateWindowDimensions() {
    
    this.setState({
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    });

    let weeksToShow = false
    switch (true) {
      case (this.state.windowInfo.width > 1400):
        weeksToShow = 52;
        this.createChard(weeksToShow);
        break
      case (this.state.windowInfo.width > 1100):
        weeksToShow = 42;
        this.createChard(weeksToShow);
        break
      case (this.state.windowInfo.width > 500):
        weeksToShow = 32;
        this.createChard(weeksToShow);
        break
      case (this.state.windowInfo.width > 350):
        weeksToShow = 20;
        this.createChard(weeksToShow);
        break
      default:
        weeksToShow = 14;
        this.createChard(weeksToShow);
        break
    }


    if (weeksToShow) {
      this.setState({ weeksToShow: weeksToShow });
    }

    //let toolTipElement = window.$('[data-toggle="tooltip"]');
    //console.log(toolTipElement)
    //toolTipElement.tooltip();
    hideAllTooltips();
    window.$('[data-toggle="tooltip"]').data('bs.tooltip',false);
    window.$('[data-toggle="tooltip"]').tooltip();

  }

  createChard(weeksToShow) {
    let chart = [];
    let dates = [];
    let day = 0;
    let week = 0;
    for (let i = 0; i < weeksToShow; i++) {
      chart[week] = []
      //let week_day = 0
      for (let i = 0; i < 7; i++) {
        chart[week].push(day);
        day++
        dates.push(this.getDateFormatFromLessDays(day));
        //week_day++
      }
      week++
    }

    this.setState({
      chart: chart,
      dates: dates.reverse()
    });

  }

  getDateFormatFromLessDays(n_days) {
    let day = new Date();
    day.setDate(day.getDate() - n_days + 1);
    day = day.toISOString().split('T')[0];
    return day;
  }

  getCompletedTasks() {
    if (getCookie('token')) {
      this.setState({ loadingCompletedTasks: true })
      fetch(config.backtAdress + '/getCompletedTasks?q=' + this.props.userId, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getCookie('token')
        },
      })
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            this.setState({
              completedTasks: res.completedTasks,
            });
          } else {
            console.error(res.error);
          }
        })
    }
  }


  countTasksFromDay(dayString) {
    if (this.state.completedTasks) {
      let completedTasks = [...this.state.completedTasks];
      completedTasks = completedTasks.filter(function (task) {
        let completedDay = task.createdAt.split('T')[0];
        return completedDay === dayString
      });
      return completedTasks.length
    }
  }

  render() {
    if (this.state.chart[0] !== undefined) {
      return (
        <div>
          <h4 className='display-1 mt-5 d-flex justify-content-center text-center' style={{ fontSize: 20 }}>Completed tasks in the past days</h4>
          <div className='container'>
            {this.state.chart[0].map((v, k) => {
              return (
                <div key={k} className='row d-flex justify-content-center'>{
                  this.state.chart.map((v2, k2) => {
                    let splitedDate = this.state.dates[v2[v]].split('-')
                    let squareDate = new Date(splitedDate[0],splitedDate[1] - 1 ,splitedDate[2])
                    let splitedSquareDate = squareDate.toDateString().split(" ")
                    squareDate = `${splitedSquareDate[1]} ${splitedSquareDate[2]}, ${splitedSquareDate[3]}`
                    let n_events = this.countTasksFromDay(this.state.dates[v2[v]])
                    /*if (Math.random() > 0.1){
                      n_events = Math.random() * 11
                    }*/
                    if (n_events > 9) {
                      this.color = '#a98600'
                    } else if (n_events < 9 && n_events >= 7) {
                      this.color = '#dab600'
                    } else if (n_events < 7 && n_events >= 5) {
                      this.color = '#e9d700'
                    } else if (n_events < 5 && n_events >= 3) {
                      this.color = '#f8ed62'
                    } else if (n_events < 3 && n_events > 0) {
                      this.color = '#fff9ae'
                    } else {
                      this.color = '#ededed'
                    }

                    return (                      
                      <button 
                        key={k2} 
                        style={{ backgroundColor: this.color }} 
                        type="button" className='square btn btn-smaller' 
                        data-toggle="tooltip"
                        data-placement="top"
                        title={`${n_events} tasks completed on ${squareDate}`}>
                      </button>
                    )
                    
                  })
                }</div>
              )
            })}
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}