import React from 'react'
import {AiOutlineArrowUp} from 'react-icons/ai'
const UpVote = props =>{
  const {voteValue,handleVote} = props
  if (voteValue > 0){
    return (
      <button onClick={(e)=>handleVote(e,1)} className='btn btn-primary rounded-pill p-0'>
        <AiOutlineArrowUp style={{transform:'translateY(-2px)'}}/>
      </button>
    )
  }
  return (
    <button onClick={(e)=>handleVote(e,1)} className='btn btn-light rounded-pill p-0'>
      <AiOutlineArrowUp style={{transform:'translateY(-2px)'}}/>
    </button>
  )
}
export default UpVote