import React from "react";

import Navbar from "../components/navbar";
import DailyChard from "../components/profile/dailyChard";
import UserNextTasks from "../components/profile/UserNextTasks";
import SideMenu from "../components/sideMenu/sideMenu";

import config from "../config/config";
import getCookie from "../utils/getCookie";

const profilePicture = {
  minWidth: "150px",
  maxWidth: "150px",
  minHeight: "150px",
  maxHeight: "150px",
  margin: 0,
  padding: 0,
  alignItems: "center",
  backgroundImage: `url("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "40% 60%",
};

export default class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.hideLoginComponent = this.hideLoginComponent.bind(this);
    this.showLoginComponent = this.showLoginComponent.bind(this);
    this.toggleSignUp = this.toggleSignUp.bind(this);
  }

  componentDidMount() {
    this.getProfile();

    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  getProfile() {
    const { userId } = this.props.match.params;
    let headers;

    if (getCookie("token")) {
      headers = {
        "Content-Type": "application/json",
        Authorization: getCookie("token")
          ? "Bearer " + getCookie("token")
          : null,
      };
    } else {
      headers = { "Content-Type": "application/json" };
    }

    fetch(config.backtAdress + "/getprofile?q=" + userId, {
      method: "get",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.userInfo){
          this.setState({
            userInfo: res.userInfo,
            loading: false,
          });
        } else {
          setTimeout(()=>this.getProfile(),1000)
        }
      });
  }

  showLoginComponent(props) {
    if (props && props.signUp) {
      this.setState({ signUpState: true });
    } else {
      this.setState({ signUpState: false });
    }
    this.setState({ loginState: true });
  }

  hideLoginComponent() {
    this.setState({
      loginState: false,
      requestMyVotes: true,
    });
  }

  toggleSignUp() {
    this.setState({
      signUpState: !this.state.signUpState,
    });
  }

  render() {
    if (!getCookie("token")) {
      return (
        <div>
          <Navbar
            loginState={this.state.loginState}
            signUpState={this.state.signUpState}
            showLoginComponent={this.showLoginComponent}
            hideLoginComponent={this.hideLoginComponent}
            toggleSignUp={this.toggleSignUp}
          />
          <div className="p-nav"></div>
          <h5 className="display-3 text-center">Private content</h5>
          <h5 className="display-4 text-center">You must be login</h5>

        </div>
      );
    }

    if (this.state.loading) {
      return (
        <div>
          <Navbar />
          <div className="p-nav"></div>
          <div className="row container-fluid p-0 m-0">
            {getCookie("token") ? <SideMenu /> : null}
            <div className="col">
              <div style={{ maxWidth: 900 }} className="container">
                Loading...
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Navbar />
        <div className="p-nav"></div>
        <div className="container p-0">
          <div className="row container p-0 pb-5 m-0">
            {getCookie("token") ? <SideMenu /> : null}
            <div className="col container p-0 m-0">
              <div style={{ maxWidth: 900 }} className="container p-0 m-0">
                <div className="row m-2">
                  <div
                    className={
                      this.state.windowInfo.width > 600
                        ? "col-sm-5 d-flex align-items-center justify-content-end pl-4"
                        : "col-sm d-flex align-items-center justify-content-center"
                    }
                  >
                    <div
                      style={profilePicture}
                      className="container-fluid rounded-circle"
                    ></div>
                  </div>
                  <div
                    className={
                      this.state.windowInfo.width > 600
                        ? "col-sm d-flex align-items-center justify-content-start pl-4"
                        : "col-sm text-center d-flex align-items-center justify-content-center"
                    }
                  >
                    <h1 className="display-3">{`${this.state.userInfo.name} ${this.state.userInfo.lastname}`}</h1>
                  </div>
                </div>
                <DailyChard userId={this.props.match.params["userId"]} />
                {this.state.userInfo.owner ? <UserNextTasks /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
