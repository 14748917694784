import React from 'react';
import ReactDOM from 'react-dom';
import {Route,Switch,BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import Home from './pages/home';
import Account from './pages/account';
import Register from './pages/register';
import Group from './pages/group';
import NewGroup from './pages/newGroup';
import Post from './pages/post';
import PasswordRecover from './pages/passwordRecover';
import About from './pages/about';
import Contact from './pages/contact';
import Trending from './pages/trending';
import Search from './pages/search';
import Profile from './pages/profile';
import Calendar from './pages/calendar';

import './index.css';
import './utils/animate.min.css';
import './utils/bootstrap.css';
import './utils/loading.css';
import 'react-notifications-component/dist/theme.css';

ReactDOM.render(
  <BrowserRouter>
  <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/trending" component={Trending}/>
      <Route exact path="/search" component={Search}/>
      <Route exact path="/account" component={Account}/>

      <Route path="/profile/:userId" component={Profile}/>

      <Route path="/calendar" component={Calendar}/>

      <Route path="/register" component={Register}/>
      <Route path="/newgroup" component={NewGroup}/>
      <Route path="/group/:groupAdress" component={Group}/>
      <Route path="/post/:groupAdress/:postId/:postTitle" component={Post}/>
      <Route path="/passwordrecover" component={PasswordRecover}/>
      <Route path="/about" component={About}/>
      <Route path="/contact" component={Contact}/>
      
  </Switch>
</BrowserRouter>,
  document.getElementById('root')
);
serviceWorker.unregister();