import React from 'react';
import Modal from "react-bootstrap/Modal";
import { AiOutlineClockCircle } from 'react-icons/ai';
import EditEventModal from './EditEventModal';
import ConfirmEditRecurringEventModal from './ConfirmEditRecurringEventModal';
import getCookie from '../../utils/getCookie';
import config from '../../config/config.json';


import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";


let eventGuid = 0;
export function createEventId() {
  return String(eventGuid++)
}
//let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
export default class UserCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      calendar: null,
      handleDaysOfWeek: []
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleEvents = this.handleEvents.bind(this);
    this.handleEventResize = this.handleEventResize.bind(this);
    this.handleNewTaskTitle = this.handleNewTaskTitle.bind(this);
    this.handleNewTaskDescription = this.handleNewTaskDescription.bind(this);
    this.addNewTask = this.addNewTask.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.toggleShowEditEventModal = this.toggleShowEditEventModal.bind(this);
    this.toggleShowConfirmEditRecurringEventModal = this.toggleShowConfirmEditRecurringEventModal.bind(this);
    this.calendarRef = React.createRef();
  }

  getAllTasks() {
    if (getCookie('token')) {

      this.setState({ loadingTasks: true })
      fetch(config.backtAdress + '/getMyTasks', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getCookie('token')
        },
      })
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            this.setState({
              events: res.tasks
            });
            res.tasks.map((task, key) => {
              console.log(task)
              if (task.daysOfWeek.length > 0) {

                this.state.calendar.addEvent({
                  id: createEventId(),
                  taskId: task._id,
                  title: task.title,
                  startTime: task.startTime,
                  endTime: task.endTime,
                  allDay: task.allDay,
                  daysOfWeek: task.daysOfWeek
                })

              } else {

                this.state.calendar.addEvent({
                  id: createEventId(),
                  taskId: task._id,
                  title: task.title,
                  start: task.start,
                  end: task.end,
                  allDay: task.allDay,
                })

              }
              return null
            });

          } else {
            console.error(res.error);
          }
        })

    }

  }


  sendNewTask(taskObject) {
    return new Promise((resolve, reject) => {

      try {

        if (getCookie('token')) {
          this.setState({ sendingTasks: true })
          fetch(config.backtAdress + '/newTask', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + getCookie('token')
            },
            body: JSON.stringify({
              title: taskObject.title,
              start: taskObject.start,
              end: taskObject.end,
              startTime: taskObject.startTime,
              endTime: taskObject.endTime,
              allDay: taskObject.allDay,
              daysOfWeek: taskObject.daysOfWeek
            })
          })

            .then(res => res.json())
            .then(res => {

              if (res.success) {
                resolve(res.taskId);
                this.hideModal();
                this.resetNewTask();
              } else {
                console.error(res.error);
              }
              this.setState({ sendingTasks: false });

            })

        } else {
          console.error('Unauthorized')
          reject(400)
        }

      } catch (error) {
        console.error(error)
        reject(400)
      }

    })
  }

  componentDidMount() {

    const calendar = new Calendar(this.calendarRef.current, {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        rrulePlugin,
        interactionPlugin
      ],
      initialView: "timeGridWeek",     
      headerToolbar:{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      selectable: true,
      editable: true,
      nowIndicator:true,
      selectMirror:true,
      select:this.handleDateSelect,
      eventClick:this.handleEventClick,
      eventsSet:this.handleEvents,
      eventResize:this.handleEventResize,
      eventDrop:this.handleEventResize,
    });

    calendar.render();
    this.setState({ calendar });

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions);

    calendar.addEvent({
      title: "Testestestes",
      rrule: "DTSTART:20200610T103000\nRRULE:FREQ=DAILY;INTERVAL=1;UNTIL=20210801\nEXDATE:20190618T103000Z\nEXDATE:20190619T103000",
      duration: "02:00"
    })
    this.getAllTasks()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    });
  }

  handleEventClick = (clickInfo) => {
    let taskId = clickInfo.event._def.extendedProps.taskId;
    let selectedEventInState = this.state.events.filter((event) => {
      return event._id === taskId
    })
    this.setState({
      selectedEvent: selectedEventInState[0],
      selectedEventInfo: clickInfo
    });
    this.toggleShowEditEventModal()
  }

  handleEventResize = (clickInfo) => {
    let taskId = clickInfo.event._def.extendedProps.taskId;
    let selectedEventInState = this.state.events.filter((event) => {
      return event._id === taskId
    })
    this.setState({
      selectedEvent: selectedEventInState[0],
      selectedEventInfo: clickInfo
    });
    this.toggleShowConfirmEditRecurringEventModal()
  }

  addNewTask = async () => {
    let taskObject
    let calendarApi = this.calendarRef.current._calendarApi;

    if (this.state.handleDaysOfWeek.length > 0) {

      taskObject = {
        id: createEventId(),
        title: this.state.handleNewTaskTitle ? this.state.handleNewTaskTitle : "New Task Title",
        start: this.state.handleNewTaskStart,
        end: this.state.handleNewTaskEnd,
        startTime: this.state.handleNewTaskStart.split('T')[1],
        endTime: this.state.handleNewTaskEnd.split('T')[1],
        allDay: this.state.handleNewTaskAllDay ? true : undefined,
        daysOfWeek: this.state.handleDaysOfWeek
      }

    } else {

      taskObject = {
        id: createEventId(),
        title: this.state.handleNewTaskTitle ? this.state.handleNewTaskTitle : "New Task Title",
        start: this.state.handleNewTaskStart,
        end: this.state.handleNewTaskEnd,
        allDay: this.state.handleNewTaskAllDay ? true : undefined,
      }

    }

    let taskId = await this.sendNewTask(taskObject);
    taskObject.taskId = taskId;
    calendarApi.addEvent(taskObject);

  }

  resetNewTask = () => {
    this.setState({
      handleNewTaskTitle: undefined,
      handleNewTaskDescription: undefined,
      handleNewTaskStart: undefined,
      handleNewTaskEnd: undefined,
      handleNewTaskAllDay: undefined,
      handleDaysOfWeek: []
    });
  }

  buildDaysOfWeekCheckBoxes() {
    let daysOfWeek = ['0', '1', '2', '3', '4', '5', '6']
    let daysOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return (
      <div className='row container'>
        {daysOfWeek.map((value, key) => {
          if (this.state.handleDaysOfWeek.includes(value)) {
            return (
              <button key={key} className='btn active btn-light form-check col-sm' onClick={e => this.handleDaysOfWeekRepetion(value)}>
                <label className='form-check-label'>{daysOfWeekNames[value]}</label>
              </button>
            )
          } else {
            return (
              <button key={key} className='btn btn-light form-check col-sm' onClick={e => this.handleDaysOfWeekRepetion(value)}>
                <label className='form-check-label'>{daysOfWeekNames[value]}</label>
              </button>
            )
          }
        })}
      </div>
    )

  }

  handleDaysOfWeekRepetion = (weekDay) => {

    let auxHandleDaysOfWeek = this.state.handleDaysOfWeek;

    if (auxHandleDaysOfWeek.includes(String(weekDay))) {

      const index = auxHandleDaysOfWeek.indexOf(String(weekDay));
      if (index > -1) {
        auxHandleDaysOfWeek.splice(index, 1);
      }

    } else {
      auxHandleDaysOfWeek.push(String(weekDay));
    }

    this.setState({
      handleDaysOfWeek: auxHandleDaysOfWeek
    })
  }

  handleNewTaskTitle(event) {
    this.setState({
      handleNewTaskTitle: event.target.value
    })
  }

  handleNewTaskDescription(event) {
    this.setState({
      handleNewTaskDescription: event.target.value
    })
  }

  handleDateSelect = async (selectInfo) => {

    this.showModal();
    console.log(selectInfo.startStr);
    console.log(selectInfo.endStr);
    this.setState({
      handleNewTaskStart: selectInfo.startStr,
      handleNewTaskEnd: selectInfo.endStr,
      handleNewTaskAllDay: selectInfo.allDay
    });

  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    })

  }

  hideModal = () => {
    this.setState({
      showNewTaskModal: false
    })
  }

  showModal = () => {
    this.setState({
      showNewTaskModal: true
    })
  }

  toggleShowEditEventModal = () => {
    this.setState({
      showEditEventModal: !this.state.showEditEventModal
    });
  }

  toggleShowConfirmEditRecurringEventModal = () => {
    this.setState({
      showConfirmEditRecurringEventModal: !this.state.showConfirmEditRecurringEventModal
    });
  }

  render() {

    return <div>
      <div className='container-fluid p-0 m-0'>
        <div>

          <div ref={this.calendarRef} />;

          <EditEventModal
            show={this.state.showEditEventModal}
            toggleShowEditEventModal={this.toggleShowEditEventModal}
            selectedEvent={this.state.selectedEvent}
            selectedEventInfo={this.state.selectedEventInfo}
          />

          <ConfirmEditRecurringEventModal
            show={this.state.showConfirmEditRecurringEventModal}
            toggleModal={this.toggleShowConfirmEditRecurringEventModal}
            selectedEvent={this.state.selectedEvent}
            selectedEventInfo={this.state.selectedEventInfo}
          />

          <Modal show={this.state.showNewTaskModal} onHide={this.hideModal}>
            <Modal.Header>
              <Modal.Body className='container-fluid p-0 m-0'>
                <input placeholder="New Task Title" onChange={this.handleNewTaskTitle} className='w-100 form-control form-control-lg border-0 p-1 m-0'></input>
              </Modal.Body>
            </Modal.Header>
            <Modal.Body className='container-fluid pr-3 pl-3 pt-2 pb-2'>
              <textarea placeholder='Description' onChange={this.handleNewTaskDescription} className='w-100 form-control border-0 p-1 m-0'></textarea>
              <div className='pt-2 pb-2'>
                <AiOutlineClockCircle className='mr-1' style={{ transform: 'translateY(-2px)' }} />
                <div className='d-inline pr-4'>
                  Start: {this.state.handleNewTaskStart}
                </div>
                <AiOutlineClockCircle className='mr-1' style={{ transform: 'translateY(-2px)' }} />
                <div className='d-inline'>
                  End: {this.state.handleNewTaskEnd}
                </div>
              </div>
              Allday {String(this.state.handleNewTaskAllDay)}
              <div className='small pt-2'>Recurring event</div>
              {this.buildDaysOfWeekCheckBoxes()}
            </Modal.Body>
            <Modal.Footer>
              <button className='btn btn-light' onClick={this.hideModal}>Cancel</button>
              <button className='btn btn-primary' onClick={this.addNewTask}>Save</button>
            </Modal.Footer>
          </Modal>

        </div>
      </div>
    </div >
  }
}