
function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = Math.floor(seconds / 31536000);
    if (interval > 0) {
      if (interval === 1) {
        return interval + " year ago";
      }
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 0) {
      if (interval === 1) {
        return interval + " month ago";
      }
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      if (interval === 1){
        return interval + " day ago";
      }
      return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      if (interval === 1) {
        return interval + " hour ago"
      }
      return interval + " hours ago"
    }
    interval = Math.floor(seconds / 60)
    if (interval > 0) {
      if (interval === 1) {
        return interval + " minute ago"
      }
      return interval + " minutes ago"
    }
    return "just now";
  }
  export default timeSince