import React from 'react'
import getCookie from '../utils/getCookie'
import config from '../config/config'
import Login from '../components/login'
import Loading from '../components/loadingAnimation'

const CreateGroupButton = () => {
  return <button style={{minWidth:70}} type='submit' className='btn btn-secondary shadow'>Create</button>
}

export default class sendPost extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      private:false
    }
    this.handleGroupDescription = this.handleGroupDescription.bind(this)
    this.handleGroupName = this.handleGroupName.bind(this)
    this.handleGroupPrivacy = this.handleGroupPrivacy.bind(this)
    this.hideLoginComponent = this.hideLoginComponent.bind(this)
    this.groupNameRef = new React.createRef()
    this.groupDescriptionRef = new React.createRef()
    this.sendForm = this.sendForm.bind(this)
  }

  componentDidMount(){
  }

  sendForm(e){
    e.preventDefault()
    if (getCookie('token')){
      this.setState({sendingPost:true})
      fetch(config.backtAdress + '/userRequest',{
      method:'post',
      headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+getCookie('token')
      },
      body:JSON.stringify({
        query:'newGroup',
        groupName:this.state.groupName,
        groupDescription:this.state.groupDescription,
        private:this.state.private
        })
      })
      .then(res=>res.json())
      .then(res=>{
        if (res.success){
          window.location = '/group/'+res.newGroup.groupAdress
        }
      })
    } else {
      this.setState({
        showLoginComponent:true
      })
    }
  }

  handleGroupPrivacy(e){
    this.setState({
      private:!this.state.private
    })
  }

  handleGroupDescription(e){
    this.setState({
      groupDescription:e.target.value
    })
  }

  handleGroupName(e){
    this.setState({
      groupName:e.target.value
    })
  }

  hideLoginComponent(){
    this.setState({
      showLoginComponent:false
    })
  }

  buildLoginComponent(){
    if (this.state.showLoginComponent){
      return (
      <div className='container-fluid p-0'>
        <div onClick={this.hideLoginComponent} className='container-fluid insulfilm d-flex align-items-center justify-content-center'>
          <div onClick={event=>{event.stopPropagation()}} style={{width:450}} className='text-left'>
            <Login hideLoginComponent={this.hideLoginComponent}/>
          </div>
        </div>
      </div>
      )
    }
  }

  render(){ 
    return (
      <div className='container-fluid p-0'>
        {this.buildLoginComponent()}
        <h5 className='font-sm pt-3'>Start a new group</h5>
        <form onSubmit={this.sendForm}>
          <input ref={this.groupNameRef} onChange={this.handleGroupName} placeholder='Group name' className='form-control form-control-lg p-2 border-0 mb-1 display-4 shadow-sm'></input>
          <textarea ref={this.groupDescriptionRef} onChange={this.handleGroupDescription} placeholder='Description' className='form-control form-control-sm border-0 shadow-sm'></textarea>
          <div className='pt-2'>
          <div className="custom-control custom-checkbox my-1 mr-sm-2">
            <input onChange={this.handleGroupPrivacy} type="checkbox" className="custom-control-input" id="customControlInline"></input>
            <label className="custom-control-label" for="customControlInline">Private</label>
          </div>
          <div className='mt-3 mb-5 p-0'>
          {this.state.sendingPost ? <button style={{ minWidth: 70 }} type='submit' className='btn btn-primary shadow'><Loading type={'small'}/></button> : <CreateGroupButton/>}
          </div>
          </div>
        </form>
      </div>
    );
  }
}