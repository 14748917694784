import React from 'react'
import {Link} from 'react-router-dom'
import arrayToChunk from '../utils/arrayToChunks'
import {IoIosArrowDropleftCircle, IoIosArrowDroprightCircle} from 'react-icons/io'
const circleCarouselIndicator = { width: 10, height: 10 }
const groupCardStyle = (props)=>{
  return {
  minWidth:220,
  minHeight:220,
  margin:0,
  padding:0,
  alignItems:'center',
  backgroundSize:'cover',
  backgroundRepeat:'no-repeat',
  backgroundImage:`linear-gradient( rgba(0,0,0,0) 0%,rgba(0,0,0,0.5)), url(${props.url})`,
  backgroundPosition:'40% 60%',
  }
}

const buildCard = (props) => {
  const {
    _id,
    groupName,
    groupDescription
  } = props
  return (
    <div key={_id} className={`col container-fluid p-2 pt-5 pb-5 d-flex justify-content-center`}>
      <Link 
        style={groupCardStyle({url:'https://learnenglish.britishcouncil.org/sites/podcasts/files/styles/article/public/RS8100_GettyImages-872024772-hig.jpg?itok=d1jggsov'})} 
        to={`/group/${groupName.replace(/ /g,"_").toLowerCase()}`}
        className='no-decoration text-white text-shadow shadow p-2 card rounded-lg hover-scale'>
        <div className='container-fluid d-flex align-items-end h-100 p-0'>
          <h5 className='font-md p-0'>{groupName}</h5>
        </div>
        <div className='container-fluid text-left p-0'>{groupDescription}</div>
      </Link>
    </div>
  )
}

export default class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.refCarousel = React.createRef();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const chunkLength = this.state.width > 550 ? 2 : 1
    const chunkedArray = arrayToChunk(this.props.arrayOfContent,chunkLength)
    return (
      <div 
        ref={this.refCarousel}
        data-interval="false"
        id="carouselIndicators"
        className="carousel slide">
        <ol className="carousel-indicators">
          {
            chunkedArray.map((content,key)=>{
              return (
                <li 
                  key={`carousel_indicator_${key}_${chunkLength}`}
                  style={circleCarouselIndicator}
                  data-target="#carouselIndicators"
                  data-slide-to={key} 
                  className={`${key === 0 ? 'active':null} rounded-circle`}>
                </li>
              )
            })
          }
        </ol>
        <div className="carousel-inner container-fluid">
            {
            chunkedArray.map((content,key)=>{
              return (
                <div 
                  key={`carousel_container_${key}_${chunkLength}`} 
                  className={` carousel-item ${key === 0 ? 'active': null}`}
                >
                  <div className='row p-0'>
                    {
                      [...Array(chunkLength).keys()].map((value)=>{
                        return (
                          <div key={`carousel_item_${content[value]._id}`} className='col container-fluid p-0 m-0'>
                          {buildCard(content[value])}
                        </div>
                        )
                      })
                    }
                  </div>
                </div>
                )
              })
            }
        </div>
        <a className='carousel-control-prev text-dark' href='#carouselIndicators' role='button' data-slide='prev'>
          <span className='pr-5 display-3 text-shadow text-secondary'  aria-hidden='false'><IoIosArrowDropleftCircle/></span>
          <span className='sr-only text-dark'>Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
        <span className='pl-5 display-3 text-shadow text-secondary'  aria-hidden='false'><IoIosArrowDroprightCircle/></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

    )
  }
}