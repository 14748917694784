import React from 'react'
import { Link } from 'react-router-dom'
import config from '../config/config'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SendComment from '../components/sendComment'
import SinglePost from '../components/singlePost'
import BuildLoadMoreButton from '../components/buildLoadMoreButton'
import DownVote from '../components/vote/downVote'
import UpVote from '../components/vote/upVote'

import arrayToTree from '../utils/arrayToTree'
import getCookie from '../utils/getCookie'
import hasLastPost from '../utils/hasLastPost'
import removePost from '../utils/posts/removePost'

export default class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voteValue: 0,
      hasMyVote: 0,
      postsArray: [],
      postsHierarchy: [],
      loadingPost: true,
      requestPostsHierarchy: false,
    }
    this.loadMoreComments = this.loadMoreComments.bind(this)
    this.buildPostsHierarchy = this.buildPostsHierarchy.bind(this)
    this.addNewPosts = this.addNewPosts.bind(this)
    this.removePostAndUpdateState = this.removePostAndUpdateState.bind(this)
    this.handleVote = this.handleVote.bind(this)


    this.hideLoginComponent = this.hideLoginComponent.bind(this)
    this.showLoginComponent = this.showLoginComponent.bind(this)
    this.toggleSignUp = this.toggleSignUp.bind(this)

  }

  removePostAndUpdateState(postIdToRemove) {
    const newPostArray = removePost({
      oldPostArray: this.state.postsArray,
      postIdToRemove: postIdToRemove
    })
    this.setState({
      postsArray: newPostArray,
      requestPostsHierarchy: true,
    })
  }

  componentDidMount() {
    this.postsStateListener()
    const { postId } = this.props.match.params
    this.getPosts(postId)
    this.getMyVote(postId)
    window.addEventListener('scroll', () => this.detectScrollAtBottom())
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', () => this.detectScrollAtBottom());
    this.setState({
      postsArray: [],
      postsHierarchy: [],
      loadingPost: true,
      requestPostsHierarchy: false,
    })
  }

  showLoginComponent(props) {
    if (props && props.signUp) {
      this.setState({ signUpState: true })
    } else {
      this.setState({ signUpState: false })
    }
    this.setState({ loginState: true })
  }

  hideLoginComponent() {
    this.setState({ loginState: false })
  }

  toggleSignUp() {
    this.setState({
      signUpState: !this.state.signUpState
    })
  }

  detectScrollAtBottom() {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 1) {
      this.loadMoreComments()
    }
  }

  postsStateListener() {
    if (this.state.requestPostsHierarchy) {
      this.buildPostsHierarchy()
    }
    setTimeout(() => {
      this.postsStateListener()
    }, 500)
  }

  handleVote(e, buttonValue) {
    if (!getCookie('token')) {
      this.showLoginComponent()
      return null
    }
    let voteValue
    if (buttonValue === this.state.voteValue) {
      voteValue = 0
    } else {
      voteValue = buttonValue
    }
    this.setState({
      voteValue: voteValue,
    })
    this.sendVote(voteValue)
  }



  sendVote(voteValue) {
    const { postId } = this.props.match.params
    fetch(config.backtAdress + '/userRequest', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getCookie('token')
      },
      body: JSON.stringify({
        query: 'sendVote',
        postId: postId,
        voteValue: voteValue,
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          //console.log(res)
        } else {
          this.setState({ voteValue: 0 })
          console.log('error', res.err)
        }
      })
  }

  getPosts(postId) {
    fetch(config.backtAdress + '/post?q=' + postId + '&offset=0', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            postsArray: res.postsResponse,
            requestPostsHierarchy: true
          })
        } else {
          console.log('error', res.err)
        }
      })
  }

  getMyVote(postId) {
    fetch(config.backtAdress + '/userRequest', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getCookie('token')
      },
      body: JSON.stringify({
        query: 'getMyVote',
        postId: postId,
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          let hasMyVote = 0
          if (res.myVote !== 0) {
            res.myVote > 0 ? hasMyVote = -1 : hasMyVote = 1
          }
          this.setState({
            voteValue: res.myVote,
            hasMyVote: hasMyVote
          })
        } else {
          console.log('error', res.err)
        }
      })
  }

  async loadMoreComments(props) {
    if (this.state.postsArray.length < 1) {
      this.setState({
        requestPostsHierarchy: true,
      })
      return null
    }

    let postId, offset
    if (!props) {
      if (await hasLastPost(this.state.postsHierarchy.childrens)) {
        return null
      }
      postId = this.props.match.params.postId
      offset = this.state.postsHierarchy.childrens.length
      if (offset > 0) {
        offset = offset - 1
      }
    } else {
      postId = props.postId
      offset = props.offset
    }

    let postsArray = this.state.postsArray
    postsArray.map((postOfMap) => {
      if (postOfMap.postId === postId) {
        postOfMap.loading = true
      }
      return null
    })

    this.setState({
      postsArray,
      loadingMoreComments: true,
    })

    fetch(config.backtAdress + '/post?q=' + postId + '&offset=' + offset, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.postsResponse) {
          this.addNewPosts(postId, res.postsResponse)
        }
      })
  }

  addNewPosts(parentPostId, arrayToAdd) {
    let postsArray = [...this.state.postsArray]
    for (let postToAdd of arrayToAdd) {
      let isDuplicate = false
      postsArray.map((postAlreadyIn) => {
        if (postToAdd.postId === postAlreadyIn.postId) {
          isDuplicate = true
          postAlreadyIn = postToAdd
        }
        if (postToAdd.lastPost && postAlreadyIn.postId === parentPostId) {
          postAlreadyIn.hasComments = false
        }
        return null
      })
      if (!isDuplicate) {
        postsArray.push(postToAdd)
      }
    }
    postsArray.map((postOfMap) => {
      if (postOfMap.postId === parentPostId) {
        postOfMap.loading = false
      }
      return null
    })
    this.setState({
      postsArray,
      requestPostsHierarchy: true,
    })
  }

  buildPostsHierarchy() {
    const { postId } = this.props.match.params
    if (this.state.postsArray && this.state.postsArray.length > 0) {
      let postsHierarchyAux = arrayToTree([...this.state.postsArray], postId)[0]
      this.setState({
        postsHierarchy: postsHierarchyAux,
        requestPostsHierarchy: false,
        loadingPost: false,
        loadingMoreComments: false,
      })
    }
  }

  buildChildPosts() {
    const { groupAdress } = this.props.match.params
    return (
      <div>
        {this.state.postsHierarchy.childrens.length > 0 ? <h5 className='font-sm pt-5'>Comments</h5> : null}
        {this.state.postsHierarchy.childrens.map((child, key) => {
          return (
            <div key={child.postId}>
              <SinglePost
                showLoginComponent={this.showLoginComponent}
                mainComment={true}
                groupAdress={groupAdress}
                post={child}
                loadMoreComments={this.loadMoreComments}
                loading={child.loading}
                addNewPosts={this.addNewPosts}
                removePostAndUpdateState={this.removePostAndUpdateState}
              >
                <BuildLoadMoreButton
                  loadMoreComments={this.loadMoreComments}
                  parent={this.state.postsHierarchy}
                  child={child}
                  loading={child.loading}
                  key_={key}
                />
              </SinglePost>
            </div>
          )
        })}
      </div>
    )
  }

  componentWillReceiveProps(nextProps) {
    const newPostId = nextProps.match.params.postId;
    if (this.props.match.params.postId !== newPostId) {
      this.setState({
        voteValue: 0,
        hasMyVote: 0,
        postsArray: [],
        postsHierarchy: [],
        loadingPost: true,
        requestPostsHierarchy: false,
      })
      this.getMyVote(newPostId)
      this.getPosts(newPostId)
    }
  }

  buildMainPost() {
    if (this.state.postsHierarchy.length < 1) {
      return (
        <div className='m-5 spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      )
    }
    return (
      <div className='container-fluid p-2'>
        <div className='rounded-lg mb-1 p-2 shadow-sm bg-white'>
          <div className='row container-fluid p-0'>
            <div style={{ maxWidth: 32 }} className='col p-0 pl-2 ml-2 container-fluid text-center'>
              <div>
                <UpVote voteValue={this.state.voteValue} handleVote={this.handleVote} />
              </div>
              <div>
                {this.state.postsHierarchy.pontuation + this.state.voteValue + this.state.hasMyVote}
              </div>
              <div>
                <DownVote voteValue={this.state.voteValue} handleVote={this.handleVote} />
              </div>
            </div>
            <div className='col'>
              <div>
                <small className='text-sm font-sm'>
                  Posted by
                {<Link
                    className='ml-1 font-smaller p-1 pr-2 pl-2 btn-secondary-sm rounded-pill '
                    to={`/profile/${this.state.postsHierarchy.ownerId}`}>
                    {this.state.postsHierarchy.ownerName}
                  </Link>}
                </small>
              </div>
              <h5>
                <div
                  className='no-decoration font-lg text-break '>
                  {this.state.postsHierarchy.title}
                </div>
              </h5>
              <div>
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  className='no-decoration text-break'>
                  {this.state.postsHierarchy.text}
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          <SendComment
            loginState={this.state.loginState}
            signUpState={this.state.signUpState}
            showLoginComponent={this.showLoginComponent}
            hideLoginComponent={this.hideLoginComponent}
            toggleSignUp={this.toggleSignUp}

            groupCode={this.state.postsHierarchy.groupCode}
            postId={this.state.postsHierarchy.postId}
            addNewPosts={this.addNewPosts}
          />
        }
        {this.buildChildPosts()}

      </div>
    )
  }

  render() {
    return (
      <div>
        <Navbar
          loginState={this.state.loginState}
          signUpState={this.state.signUpState}
          showLoginComponent={this.showLoginComponent}
          hideLoginComponent={this.hideLoginComponent}
          toggleSignUp={this.toggleSignUp}
        />
        <div id='fixed_bg' className='p-nav bg-light bg-fixed'>
          <div className='container-fluid main-content'>
            {this.buildMainPost()}
          </div>
          {
            this.state.loadingMoreComments ?
              <div className='container text-center'>
                <div className='spinner'>
                  <div className='bounce1 bg-white'></div>
                  <div className='bounce2 bg-white'></div>
                  <div className='bounce3 bg-white'></div>
                </div>
              </div>
              : null
          }
          <Footer />
        </div>
      </div>
    )
  }
}