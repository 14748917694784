const removePost = props => {
  let newPostArray = []
  const { oldPostArray, postIdToRemove } = props
  oldPostArray.map((post) => {
    if (post.postId !== postIdToRemove){
      newPostArray.push(post)
    }
    return null
  })
  return newPostArray
}
export default removePost