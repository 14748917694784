import React from 'react'

import Navbar from '../components/navbar'
import SendPost from '../components/sendPost'
import SinglePost from '../components/singlePost'
import Loading from '../components/loadingAnimation'
import FollowButton from '../components/group/followButton'

import config from '../config/config'
import hasLastPost from '../utils/hasLastPost'
import getCookie from '../utils/getCookie'
import removePost from '../utils/posts/removePost'

export default class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingGroup: true,
      requestSortedGroupPosts:true,
    }
    this.hideLoginComponent = this.hideLoginComponent.bind(this)
    this.showLoginComponent = this.showLoginComponent.bind(this)
    this.toggleSignUp = this.toggleSignUp.bind(this)
    this.turnOffRequestMyVotes = this.turnOffRequestMyVotes.bind(this)

    this.addNewPosts = this.addNewPosts.bind(this)
    this.removePostAndUpdateState = this.removePostAndUpdateState.bind(this)

    this.handleFollowingGroup = this.handleFollowingGroup.bind(this)
  }

  componentDidMount() { 
    this.postsStateListener()   
    this.getGroup()
    window.addEventListener('scroll', ()=>this.detectScrollAtBottom())
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', ()=>this.detectScrollAtBottom());
  }

  removePostAndUpdateState(postIdToRemove){
    const newPostArray = removePost({
      oldPostArray:this.state.postsArray,
      postIdToRemove:postIdToRemove
    })
    console.log(newPostArray)
    this.setState({
      postsArray:newPostArray,
      requestSortedGroupPosts:true,
    })
  }

  detectScrollAtBottom(){
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight -1) {
      this.loadMoreGroupPosts()
    }
  }

  handleFollowingGroup(){
    let group = this.state.group
    group.following = !group.following
    this.setState(group)
  }

  getGroup(){
    const {groupAdress} = this.props.match.params
    let headers
    if (getCookie('token')){
      headers = {
        'Content-Type':'application/json',
        'Authorization': getCookie('token') ? 'Bearer ' + getCookie('token') : null
      }
    } else {
      headers = {'Content-Type':'application/json'}
    }
    fetch(config.backtAdress+'/group?q='+groupAdress+'&offset=0',
    {
      method:'post',
      headers:headers,
    })
    .then(res=>res.json())
    .then(res=>{
      if (res.success){
        this.setState({
          group:res.group,
          postsArray:res.groupPosts,
          requestSortedGroupPosts:true,
          loadingGroup:true,
        })
      } else {
        console.log(res.err)
      }
    })
  }

  postsStateListener(){
    if (this.state.requestSortedGroupPosts){
      this.sortGroupPosts()
    }
    setTimeout(()=>{
      this.postsStateListener()
    },500)
  }

  async loadMoreGroupPosts(){
    if (!this.state.postsArray || this.state.postsArray.length < 1){
      this.setState({
        requestSortedGroupPosts:true,
      })
      return null
    }
    const {groupAdress} = this.props.match.params
    if (await hasLastPost(this.state.postsArray)){
      return null
    }

    let offset = this.state.groupPosts.length - 1
    offset = offset-1    
    this.setState({      
      loadingMoreGroupPosts:true,
    })
    fetch(config.backtAdress + '/group?q='+groupAdress+'&offset='+offset,{
      method:'get',
      headers:{
        'Content-Type':'application/json'
      }
    })
    .then(res=>res.json())
    .then(res=>{
      console.log(res.groupPosts)
      if (res.success){
        this.setState({requestSortedGroupPosts:true})
        this.addNewPosts(res.groupPosts)
      } else {
        console.log(res.err)
      }
    })
  }

  buildAllGroupPosts(){
    const {groupAdress} = this.props.match.params
    return (
    <div>
      <h5 className='font-sm pt-5'>Discussions</h5>
      {this.state.groupPosts.map((post)=>{
        return (
          <SinglePost 
            showLoginComponent={this.showLoginComponent}
            turnOffRequestMyVotes={this.turnOffRequestMyVotes}
            requestMyVotes={this.state.requestMyVotes}
            removePostAndUpdateState={this.removePostAndUpdateState}
            className='' 
            key={post.postId}
            post={post}
            mainComment={true}
            groupAdress={groupAdress}
            childrens={[]}
            />
        )
      })}
    </div>
    )
  }

  buildGroupHeader(){
    if (this.state.loadingGroup){
      return (
        <div className='m-5 spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      )
    }
    return (
      <div className='container p-2'>
        <div className='rounded-lg mb-1 p-2 shadow-sm bg-white'>
          <div className='display-4'>
            {this.state.group.groupName}
          </div>
          <div>
            {this.state.group.groupDescription}
          </div>
        </div>
        <div className='text-right'>
          <FollowButton
            group={this.state.group}
            showLoginComponent={this.showLoginComponent}
            handleFollowingGroup = {this.handleFollowingGroup}
          />
        </div>
        <SendPost 
          showLoginComponent={this.showLoginComponent}
          addNewPosts={this.addNewPosts} 
          removePostAndUpdateState={this.removePostAndUpdateState}
          groupCode={this.state.group._id}
          groupName={this.state.group.groupName}
        />
        {this.state.groupPosts ? this.buildAllGroupPosts() : null}
      </div>
    )
  }

  addNewPosts(arrayToAdd){
    if (this.state.postsArray){
      let postsArray = [...this.state.postsArray]
      for (let postToAdd of arrayToAdd){
        let isDuplicate = false
        postsArray.map((postAlreadyIn)=>{
          if (postToAdd.postId === postAlreadyIn.postId){
            isDuplicate=true
            postAlreadyIn = postToAdd
          }
          return null
        })
        if (!isDuplicate){
          postsArray.push(postToAdd)
        }
      }  
      this.setState({
        postsArray,
        requestSortedGroupPosts:true,
      })
    }    
  }

  sortGroupPosts(){
    if (this.state.postsArray && this.state.postsArray.length < 1){
      this.setState({
        requestSortedGroupPosts:false,
        groupPosts:[],        
        loadingGroup:false,
        loadingMoreGroupPosts:false,
      })
    }
    if (this.state.postsArray && this.state.postsArray.length > 0){
      let groupPosts = this.state.postsArray.sort((a,b)=>{
        let priorityA = a.priority
        let priorityB = b.priority
        if (priorityA === priorityB){
          let dateA = new Date(a.createdAt)
          let dateB = new Date(b.createdAt)
          return dateA>dateB ? 1 : dateA<dateB ? -1 : 0;
        } else {
          if (!priorityA){priorityA = 0}
          if (!priorityB){priorityB = 0}
          return priorityA>priorityB ? -1 : priorityA<priorityB ? 1 : 0;
        }
      })
      this.setState({
        requestSortedGroupPosts:false,
        groupPosts:groupPosts,
        loadingGroup:false,
        loadingMoreGroupPosts:false,
      })
    }
  }

  showLoginComponent(props){
    if (props && props.signUp){
      this.setState({signUpState:true})
    } else {
      this.setState({signUpState:false})
    }
    this.setState({loginState:true})
  }

  hideLoginComponent(){
    console.log('hideLoginComponent')
    this.setState({
      loginState:false,
      requestMyVotes:true
    })
  }

  toggleSignUp(){
    this.setState({
      signUpState:!this.state.signUpState
    })
  }

  turnOffRequestMyVotes(){
    this.setState({
      requestMyVotes:false
    })
  }

  render() {
    return (
      <div>
        <Navbar
          loginState={this.state.loginState}
          signUpState={this.state.signUpState}
          showLoginComponent={this.showLoginComponent}
          hideLoginComponent={this.hideLoginComponent}
          toggleSignUp = {this.toggleSignUp}
        />
        <div  id='fixed_bg' className='p-nav bg-light bg-fixed-2 '>
          <div className='container-fluid main-content'>
            {this.buildGroupHeader()}
          </div>
          <div className='container-fluid text-center'>
            {this.state.loadingMoreGroupPosts ? <Loading type={'normal'}/> : null}
          </div>
        </div>
      </div>
    )
  }
}