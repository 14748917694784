import React from 'react';
//import config from '../config/config';
import {Link} from 'react-router-dom';
const footer = () => {
    return (
      <div>
        <div className='border-top border-dark bg-dark'>
          <div className='row pl-5 pr-5 container-fluid'>
            <div className='col-md text-center d-flex align-items-center justify-content-center'>
              <Link to='/' style={{color:'#269c42'}} className='no-decoration text-primary display-4'>
                Caravana
              </Link>
            </div>
            <div className='col'>
              <div className='row pt-5'>
                <div className='col-sm pb-5 text-center'>
                  <div>
                    <Link className='text-white footer-normal-text' to='/contact'>Fale Conosco</Link>
                  </div>
                  <div>
                    <Link className='text-white footer-normal-text' to='/about'>Sobre nós</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default footer;