import React from 'react';
import { GoCheck } from 'react-icons/go';
import getCookie from '../../utils/getCookie';
import Loading from '../loadingAnimation';
import config from '../../config/config.json';
import { AiOutlineClockCircle } from 'react-icons/ai';
import './UserNextTasks.css';

var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
let todayStr = localISOTime.replace(/T.*$/, '') // YYYY-MM-DD of today

const removePastEvents = (event) => {
  return new Date(event.end) - new Date() > 0;
}

const removeNotTodayEvents = (event) => {
  let todayDayOfWeek = String(new Date().getDay())
  if (event.daysOfWeek.length > 0){
    return event.daysOfWeek.includes(todayDayOfWeek)
  }
  return true
}

function dateCheck(from, to, check) {

  var fDate, lDate, cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(check);

  if ((cDate <= lDate && cDate >= fDate)) {
    return true;
  }
  return false;
}


export default class UserNextTasks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nextTasks: null,
      taskForNow: null,
      sendingCompletedTask: {},
      alreadyCompletedTasks: {},
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.sendCompletedTask = this.sendCompletedTask.bind(this);
  }

  getAllTasks() {
    if (getCookie('token')) {

      this.setState({ loadingTasks: true })
      fetch(config.backtAdress + '/getMyTasks', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getCookie('token')
        },
      })
        .then(res => res.json())
        .then(res => {
          if (res.success) {

            let tasks = [...res.tasks];

            tasks.map((task) => {
              if (task.daysOfWeek.length > 0){
                //let todayDayOfWeek = String(new Date().getDay())
                //if (task.daysOfWeek.includes(todayDayOfWeek)){
                  task.start = `${todayStr}T${task.startTime}` 
                  task.end = `${todayStr}T${task.endTime}` 
                  
                //}
              }
              task.forNow = dateCheck(new Date(task.start), new Date(task.end), new Date())
              task.countdown = new Date(task.end) - new Date();
              task.startCountdown = new Date(task.start) - new Date();
              return null
            })

            tasks.sort(function (a, b) {
              return new Date(b.start) - new Date(a.start);
            }).reverse();
            
            tasks = tasks.filter(removePastEvents);
            tasks = tasks.filter(removeNotTodayEvents);

            let nextTasks = tasks.filter((task) => { return !task.forNow })
            let taskForNow = tasks.filter((task) => { return task.forNow })

            taskForNow.map((task) => this.checkCompletedTask(task._id))

            this.setState({
              nextTasks: nextTasks,
              taskForNow: taskForNow
            });

          } else {
            console.error(res.error);
          }
        })
    }
  }

  componentDidMount() {
    
    this.getAllTasks();

    this.updateCountdownTimout = setInterval(()=>this.updateCountdown(),1000);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

  }

  componentWillUnmount() {

    clearInterval(this.updateCountdownTimout);

    window.removeEventListener('resize', this.updateWindowDimensions);

  }

  updateCountdown = () => {
    if (this.state.nextTasks) {

      let tasks = this.state.nextTasks;
      tasks.map((task) => {
        task.countdown = new Date(task.end) - new Date();
        task.startCountdown = new Date(task.start) - new Date();
        return null
      })

      let tasksForNow = this.state.taskForNow;
      tasksForNow.map((task) => {
        task.countdown = new Date(task.end) - new Date();
        return null
      })

      this.setState({
        nextTasks: tasks,
        taskForNow: tasksForNow
      })

    }
  
  }

  sendCompletedTask(taskId) {
    let sendingCompletedTask = { ...this.state.sendingCompletedTask }
    sendingCompletedTask[taskId] = true
    this.setState({ sendingCompletedTask: { ...sendingCompletedTask } })
    if (getCookie('token')) {
      fetch(config.backtAdress + '/completedTask', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getCookie('token')
        },
        body: JSON.stringify({
          parentId: taskId
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            let sendingCompletedTask = { ...this.state.sendingCompletedTask }
            sendingCompletedTask[taskId] = false
            this.setState({ sendingCompletedTask: { ...sendingCompletedTask } })
          } else {
            console.error(res.error);
          }

          let alreadyCompletedTasks = { ...this.state.alreadyCompletedTasks }
          alreadyCompletedTasks[taskId] = new Date();
          this.setState({ alreadyCompletedTasks: { ...alreadyCompletedTasks } })

        })
    }
  }

  checkCompletedTask(taskId) {
    if (getCookie('token')) {
      fetch(config.backtAdress + '/completedTask', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getCookie('token')
        },
        body: JSON.stringify({
          parentId: taskId,
          check: true
        })
      })
        .then(res => res.json())
        .then(res => {

          let alreadyCompletedTasks = { ...this.state.alreadyCompletedTasks }

          if (res.error) {
            alreadyCompletedTasks[taskId] = res.createdAt
          } else {
            alreadyCompletedTasks[taskId] = false
          }
          this.setState({ alreadyCompletedTasks: { ...alreadyCompletedTasks } })

        })
    }
  }

  updateWindowDimensions() {

    this.setState({
      windowInfo: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    });

  }

  buildNextTasks() {
    if (this.state.nextTasks && this.state.nextTasks.length > 0) {
      return (

        <div>
          <h4 className='display-1 mt-2 d-flex justify-content-center' style={{ fontSize: 20 }}>Next task</h4>
          {[this.state.nextTasks[0]].map((event, key) => {
            return (
              <div className='container-fluid btn btn-light text-left radius-0' key={key}>
                <div className='row'>
                  <div className={`task-title col-sm ${this.state.windowInfo.width > 600 ? null : 'text-center'}`}>
                    {event.title}
                  </div>
                  <div className={`col d-flex pr-3 align-items-center ${this.state.windowInfo.width > 600 ? 'justify-content-end' : 'justify-content-center'}`}>
                    <div style={{ fontSize: 12 }} className='text-right justify-content-end pr-1'>Starts in</div>
                    <div> <AiOutlineClockCircle style={{ transform: 'translateY(-1px)' }} /> {String(new Date(event.startCountdown).toISOString().substr(11, 8))}s</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  buildForNowTask() {
    if (this.state.taskForNow && this.state.taskForNow.length > 0) {
      return (
        <div>
          <h4 className='display-1 mt-2 d-flex justify-content-center' style={{ fontSize: 20 }}>For now</h4>
          {this.state.taskForNow.map((event, key) => {
            if (this.state.alreadyCompletedTasks[event._id]) {
              return (
                <button onClick={(e) => this.sendCompletedTask(event._id)} className='container-fluid btn btn-light text-left radius-0 already-done-btn-parent ripple' key={key}>
                  <div className='row'>
                    <div className='done-btn-child' style={{ position: "absolute", left: "50%", top: "50%" }}>
                      <div className='done-btn-child' style={{ fontSize: 32, transform: 'translateY(-50%)', position: "relative", left: "-50%" }}>
                        <div>Done<GoCheck style={{ transform: 'translateY(-3px)' }} /></div>
                      </div>
                    </div>
                    <div className={`task-title col-sm ${this.state.windowInfo.width > 600 ? null : 'text-center'}`}>
                      {event.title}
                    </div>
                    <div className='d-flex pr-3 align-items-center col'>
                    <div className={`col d-flex pr-3 align-items-center ${this.state.windowInfo.width > 600 ? 'justify-content-end' : 'justify-content-center'}`}>
                        <div style={{ fontSize: 12 }} className='text-right justify-content-end pr-1'>Finished in</div>
                        {<div> <AiOutlineClockCircle style={{ transform: 'translateY(-1px)' }} /> {String(new Date(Date.parse(this.state.alreadyCompletedTasks[event._id]) - new Date(event.start)).toISOString().substr(11, 8))}s</div>}
                      </div>
                    </div>
                  </div>
                </button>
              )
            } else {

              let className
              if (this.state.sendingCompletedTask[event._id]) {
                className = 'container-fluid btn btn-light text-left radius-0 already-done-btn-parent ripple'
              } else {
                className = 'container-fluid btn btn-light text-left radius-0 done-btn-parent ripple'
              }

              return (

                <button onClick={(e) => this.sendCompletedTask(event._id)} className={className} key={key}>
                  <div className='row'>
                    <div className='done-btn-child' style={{ position: "absolute", left: "50%", top: "50%" }}>
                      <div className='done-btn-child' style={{ fontSize: 32, transform: 'translateY(-50%)', position: "relative", left: "-50%" }}>
                        {this.state.sendingCompletedTask[event._id] ? <Loading type='normal' /> : <div>Done<GoCheck style={{ transform: 'translateY(-3px)' }} /></div>}
                      </div>
                    </div>
                    <div className={`task-title col-sm ${this.state.windowInfo.width > 600 ? null : 'text-center'}`}>
                      {event.title}
                    </div>
                    <div className='d-flex pr-3 align-items-center col'>
                    <div className={`col d-flex pr-3 align-items-center ${this.state.windowInfo.width > 600 ? 'justify-content-end' : 'justify-content-center'}`}>
                        <div style={{ fontSize: 12 }} className='text-right justify-content-end pr-1'> Time left </div>
                        <div> <AiOutlineClockCircle style={{ transform: 'translateY(-1px)' }} /> {String(new Date(event.countdown).toISOString().substr(11, 8))}s</div>
                      </div>
                    </div>
                  </div>
                </button>
                
              )

            }
          })}
        </div>
      )
    } else {
      return null
    }
  }


  render() {
    return (
      <div style={{ maxWidth: 650 }} className='container-fluid p-3'>
        {this.buildForNowTask()}
        {this.buildNextTasks()}
      </div>
    )
  }

}