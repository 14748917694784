import React from 'react';
import config from '../config/config';
import getCookie from '../utils/getCookie';
import LoginComponent from '../components/login';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import {GiMailbox} from 'react-icons/gi';
export default class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          message : '',
          messageType : '1',
        }
    }

    handleChanges(key,value){
      let obj = {};
      obj[key] = value;
      this.setState(obj)
    }

    sendMessage(e){
      e.preventDefault()
      if (getCookie('token')){
        fetch(config.backtAdress+'/userRequest',{
          method:'POST',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('token')
          },
          body: JSON.stringify({
            query:'sendMessage',
            message:this.state.message,
            messageType:this.state.messageType
          })
        })
        .then(res=>res.json())
        .then(res=>{
          if (res.success){
            this.setState({sendedMessage:true})
          } else {
            console.log(res.error)
          }
        })
      }
    }

    render(){
      if (this.state.sendedMessage){
        return (
          <div>
            <Navbar/>
            <div className='p-5 container-fluid'>
              <div className='container-fluid' style={{maxWidth:500}} >
                <div className='row'>
                  <div style={{maxWidth:50}} className='col'>
                    <div><GiMailbox className='text-primary' style={{fontSize:52}}/></div>
                  </div>
                  <div className='col pl-5'>
                    <div className='text-md'>Sua mensagem foi entregue.</div>
                    <div className='text-sm'>Agradecemos o pelo seu feedback!</div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
          </div>
        )
      }
      if (getCookie('token')){
        return (
          <div>
            <Navbar/>
            <div className='p-5 container-fluid'>
              <div className='container-fluid' style={{maxWidth:500}} >
                <form onSubmit={(e)=>this.sendMessage(e)}>
                  <div className='text-md pb-3'>Enviar Mensagem à Equipe</div>
                  <select onChange={(e)=>{this.handleChanges('messageType',e.target.value)}} className='form-control mb-3 shadow-sm'>
                    <option value='0'>Dúvida</option>
                    <option value='2'>Sugestão</option>
                    <option value='1'>Reportar algum problema</option>
                  </select>
                  <textarea onChange={(e)=>{this.handleChanges('message',e.target.value)}} rows='5' placeholder='Mensagem...' className='form-control rounded shadow-sm' required></textarea>
                  <div className='container-fluid p-0 text-right'>
                    <button type='submit' className='btn btn-primary mt-2 pl-5 pr-5 shadow'>Enviar</button>
                  </div>
                </form>
              </div>
            </div>
            <Footer/>
          </div>
        )        
      }
      return (
        <LoginComponent/>
      )        
    }
}