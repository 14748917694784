import React from 'react';
const Loading = props => {
  if (['small', 'sm'].includes(props.type)) {
    return (
      <div className="spinner-sm">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    )
  }
  if (['normal', 'md'].includes(props.type)) {
    return (
      <div className='spinner'>
        <div className='bounce1'></div>
        <div className='bounce2'></div>
        <div className='bounce3'></div>
      </div>
    )
  }
}
export default Loading