import React from 'react'
import config from '../../config/config'
import Carousel from '../../components/carousel'

export default class trendingGroups extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      loadingGroups:true,      
    }
  }

  getTrendingGroups(){
    fetch(config.backtAdress+'/trendingGroups',{
      method:"get",
      headers:{
        "Content-Type":"application/json"
      }
    })
    .then(res=>res.json())
    .then(res=>{
      this.setState({
        trendingGroups:res.trendingGroups,
        loadingGroups:false
      })
    })
  }
  
  componentDidMount(){
    this.getTrendingGroups()
  }
  
  buildTrendingGroups(){
    if (this.state.trendingGroups){
      return (
        <div className=''>
          <div className='text-center display-4 animated fadeInDown'>
            Explore some groups to follow and improve your timeline!
          </div>
          <Carousel arrayOfContent={this.state.trendingGroups}/>
        </div>
        )
    }
    return null
  }

  render(){ 
    if (this.state.loadingGroups){
      return (
        <div className='m-5 spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      )
    }
    return (
      <div className='container '>
        <div>
          {this.buildTrendingGroups()}
        </div>
      </div>
    );
  }
}